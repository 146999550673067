import { useMemo, useState } from 'react'
import { getArticleImagesAndCaptions, mediaCdnReplace } from '../../utils'
import {
  ArrowLeftIcon,
  ArrowLeftIconDesktop,
  ArrowRightIcon,
  ArrowRightIconDesktop,
  Caption,
  CaptionDesktop,
  CloseIcon,
  CloseIconDesktop,
  Container,
  ImageAndCaption,
  NavigationOverlay,
  TouchArea,
  WrapperImages,
  WrapperText,
} from './Gallery.styles'

import XMark from '/public/assets/images/svg/x-mark.svg'

// Swiper
import { Controller, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { useOverlay, usePage } from '../../contexts'
import { IArticlePage } from '../../types'

type Props = {
  index: number
}

export const Gallery = ({ index }: Props) => {
  const page = usePage() as IArticlePage

  const { handleClose } = useOverlay()
  const [swiper, setSwiper] = useState<SwiperClass>()

  const [prevEl, setPrevEl] = useState<HTMLDivElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLDivElement | null>(null)

  const images = useMemo(
    () => getArticleImagesAndCaptions(page.Content.Item),
    [page.Content.Item]
  )

  if (!images) {
    return null
  }

  return (
    <Container onlyOnePicture={images.length === 1}>
      <CloseIcon onClick={() => handleClose()}>
        <XMark />
      </CloseIcon>
      <WrapperImages>
        <Swiper
          initialSlide={index}
          dir='ltr'
          loop
          centeredSlides={true}
          modules={[Controller, Navigation, Pagination]}
          controller={{
            control: swiper,
          }}
          className='swiper-gallery'
          navigation={{
            prevEl,
            nextEl,
          }}
          pagination={{
            type: 'fraction',
            // eslint-disable-next-line
            // @ts-ignore
            formatFractionCurrent: number => String(number).padStart(2, '0'),
            // eslint-disable-next-line
            // @ts-ignore
            formatFractionTotal: number => String(number).padStart(2, '0'),
            renderFraction: (currentClass, totalClass) =>
              `<span class='${currentClass}'></span> / <span class='${totalClass}'></span>`,
          }}
        >
          <CloseIconDesktop onClick={() => handleClose()}>
            <XMark />
          </CloseIconDesktop>
          {images.map(({ src, caption }, index) => (
            <SwiperSlide key={index}>
              <ImageAndCaption>
                {src && (
                  <img loading='lazy' src={mediaCdnReplace(src)} alt={''} />
                )}
                {caption && <CaptionDesktop>{caption}</CaptionDesktop>}
              </ImageAndCaption>
            </SwiperSlide>
          ))}
          <NavigationOverlay position='left' ref={node => setPrevEl(node)}>
            <TouchArea position='left'>
              <ArrowLeftIcon />
              <ArrowLeftIconDesktop />
            </TouchArea>
          </NavigationOverlay>
          <NavigationOverlay position='right' ref={node => setNextEl(node)}>
            <TouchArea position='right'>
              <ArrowRightIcon />
              <ArrowRightIconDesktop />
            </TouchArea>
          </NavigationOverlay>
        </Swiper>
      </WrapperImages>
      <WrapperText>
        <Swiper
          dir='ltr'
          allowTouchMove={false}
          loop
          centeredSlides={true}
          onSwiper={setSwiper}
          modules={[Pagination]}
          pagination={{
            type: 'fraction',
            // eslint-disable-next-line
            // @ts-ignore
            formatFractionCurrent: number => String(number).padStart(2, '0'),
            // eslint-disable-next-line
            // @ts-ignore
            formatFractionTotal: number => String(number).padStart(2, '0'),
            renderFraction: (currentClass, totalClass) =>
              `<span class='${currentClass}'></span> / <span class='${totalClass}'></span>`,
          }}
        >
          {images.map(({ caption }, index) => {
            return (
              <SwiperSlide key={index}>
                <Caption>{caption}</Caption>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </WrapperText>
    </Container>
  )
}
