// import dynamic from 'next/dynamic'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from '../../../client'
import { MidBoxMobileContainer } from './MidBoxMobile.style'
import { MidBox } from '../../Ads'

export const MidBoxMobile = () => {
  const className = 'mid-box-wrapper'
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [render, setRender] = useState(false)
  const [index, setIndex] = useState<number>(0)
  // const MidBox = dynamic(() => import('../../Ads/MidBox/MidBox'), {
  //   ssr: false,
  // })
  const [isEmpty, setIsEmpty] = useState(true)

  const onRender = (ev: googletag.events.SlotRenderEndedEvent) => {
    setIsEmpty(ev.isEmpty)
  }

  useEffect(() => {
    if (isMobile()) {
      setRender(true)
    }
  }, [])

  useEffect(() => {
    if (render && wrapperRef.current) {
      const all = document.querySelectorAll('.' + className)
      all.forEach((el, key) => {
        if (key < 4 && el === wrapperRef.current) {
          setIndex(key + 1)
        }
      })
    }
  }, [render])

  return (
    <MidBoxMobileContainer
      className={className}
      ref={wrapperRef}
      theme={{ isEmpty }}
    >
      {render && index && (
        <MidBox key={index} index={index} collapse={true} onRender={onRender} />
      )}
    </MidBoxMobileContainer>
  )
}
