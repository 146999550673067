export const midBoxPositions = [11, 7, 3, 2]
export const taboolaPositionsGridVOD = (i: number) =>
  i === 1 || (i > 1 && (i - 1) % 5 === 0)

export interface ILiveSources {
  mobile: {
    live_with_subs: string
    live_no_subs: string
  }
  desktop: {
    live_with_subs: string
    live_no_subs: string
  }
}

export interface IConfigFile {
  ros_interstitial: 'on' | 'off' | string
}

export const liveSourcesDefault: ILiveSources = {
  mobile: {
    live_with_subs:
      'https://reshet.g-mana.live/media/6f10d1da-0803-48d9-9272-57a811958974/mainManifest.m3u8',
    live_no_subs:
      'https://reshet.g-mana.live/media/cdefce3a-14ec-46cc-a147-1275c4a8b9ed/mainManifest.m3u8',
  },
  desktop: {
    live_with_subs:
      'https://reshet.g-mana.live/media/4607e158-e4d4-4e18-9160-3dc3ea9bc677/mainManifest.m3u8',
    live_no_subs:
      'https://reshet.g-mana.live/media/87f59c77-03f6-4bad-a648-897e095e7360/mainManifest.m3u8',
  },
}
export const configFileDefault = {
  ros_interstitial: 'off',
}
