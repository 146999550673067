import styled, { css } from 'styled-components'

export const MidBoxMobileContainer = styled.div`
  display: flex;
  justify-content: center;

  ${props =>
    props.theme.isEmpty
      ? ''
      : css`
          margin-bottom: 30px;
        `}
`
