import { RefObject } from 'react'
import create from 'zustand'
import { IPage, IPlayerScript, ITaboolaRoot } from '../types'

interface IStore {
  setPlayerScript: (playerScripts: IPlayerScript) => void
  playerScript: IPlayerScript
  page: IPage | undefined
  setPage: (page: IPage) => void
  showButtons: boolean
  toggleShowButtons: () => void
  pageTitleRef: RefObject<HTMLDivElement> | undefined
  setPageTitleRef: (ref: RefObject<HTMLDivElement>) => void
  firstPageView: boolean | null
  setFirstPageView: (value: boolean) => void
  darkTheme: boolean
  setDarkTheme: (value: boolean) => void
  isPlayerFullscreen: boolean
  setIsPlayerFullscreen: (value: boolean) => void
  hasHero: boolean
  setHasHero: (value: boolean) => void
  skipMaavaron: boolean
  setSkipMaavaron: (value: boolean) => void
  isShowingMaavaron: boolean
  setIsShowingMaavaron: (val: boolean) => void
  firstPagePath: string | null
  setFirstPagePath: (val: string) => void
  taboolaRoot: ITaboolaRoot
  setTaboolaRoot: (value: ITaboolaRoot) => void
  pageTitle: string
  setPageTitle: (value: string) => void
  pageType: string
  setPageType: (value: string) => void
  dailyClicksCount: number
  setDailyClicksCount: (value: number) => void
}

export const useRootStore = create<IStore>()((set, get) => ({
  page: undefined,
  setPage: (page: IPage | undefined) => set({ page: page }),
  showButtons: false,
  toggleShowButtons: () => set({ showButtons: !get().showButtons }),
  pageTitleRef: undefined,
  setPageTitleRef: (ref: RefObject<HTMLDivElement>) =>
    set({ pageTitleRef: ref }),
  firstPageView: null,
  setFirstPageView: (value: boolean) => set({ firstPageView: value }),
  firstPagePath: null,
  setFirstPagePath: (value: string) => set({ firstPagePath: value }),
  darkTheme: false,
  setDarkTheme: (value: boolean) => set({ darkTheme: value }),
  isPlayerFullscreen: false,
  setIsPlayerFullscreen: (value: boolean) => set({ isPlayerFullscreen: value }),
  hasHero: false,
  setHasHero: (value: boolean) => set({ hasHero: value }),
  skipMaavaron: false,
  setSkipMaavaron: (value: boolean) => set({ skipMaavaron: value }),
  isShowingMaavaron: true,
  setIsShowingMaavaron: (val: boolean) => set({ isShowingMaavaron: val }),
  setPlayerScript: (playerScript: IPlayerScript) =>
    set({ playerScript: playerScript }),
  playerScript: {
    kalturaReady: false,
    SpringStreamsReady: false,
    kantarReady: false,
  },
  taboolaRoot: {
    ready: false,
    pubName: '',
    os: '',
  },
  setTaboolaRoot: (taboolaRoot: ITaboolaRoot) =>
    set({ taboolaRoot: taboolaRoot }),
  pageTitle: '',
  setPageTitle: (value: string) => set({ pageTitle: value }),
  pageType: '',
  setPageType: (value: string) => set({ pageType: value }),
  dailyClicksCount: 1,
  setDailyClicksCount: (value: number) => set({ dailyClicksCount: value }),
  bannerContainerRef: undefined,
}))

export interface IPageLoaderStore {
  isLoading: boolean
  setLoading: (value: boolean) => void
}

export const pageLoaderStore = create<IPageLoaderStore>()(set => ({
  isLoading: true,
  setLoading: (value: boolean) => set({ isLoading: value }),
}))
