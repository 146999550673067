import styled, { css } from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

export const WeekDayContainer = styled.div`
  display: flex;
  width: 64px;
  height: 56px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    div {
      color: #fff;
      font-weight: 300;
    }
  }

  &.activeDay div {
    color: #fff;
    font-weight: 500;
  }

  &.activeDay {
    border-bottom: 1px solid #01deff;
  }
`

export const WeekDayName = styled.div`
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  color: #9592a5;

  &:hover {
    color: #fff;
    font-weight: 300;
  }
`
export const WeekDayDate = styled.div`
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  color: #9592a5;
  &:hover {
    color: #fff;
    font-weight: 300;
  }
`

export const CalendarSliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  padding-left: 3px;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  width: 737px;
  background: linear-gradient(90deg, #000721, rgba(0, 7, 33, 0) 15%);

  & .swiper {
    margin-right: 0;
    margin-left: auto;
    top: 12px;
  }
`

export const SwiperButtonWrapper = styled.div`
  border: 1px solid #33394d;
  background: #33394d;
  opacity: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: opacity 0.1s ease-in;
  top: 45px;
  position: relative;

  &:hover {
    opacity: 1;
  }
`

export const SwiperButton = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  background-size: contain;

  ${props => {
    switch (props.theme.role) {
      case 'prev':
        return css`
          background-image: url(https://media3.reshet.tv/image/upload/v1716456497/uploads/2024/904069003.png);
          ${device.medium} {
            background-image: url(https://media3.reshet.tv/image/upload/v1716456497/uploads/2024/904069003.png);
          }
        `
      case 'next':
        return css`
          background-image: url(https://media3.reshet.tv/image/upload/v1716706934/uploads/2024/904071933.png);
          right: 5.5%;
          position: relative;
          ${device.medium} {
            background-image: url(https://media3.reshet.tv/image/upload/v1716706934/uploads/2024/904071933.png);
            right: 0;
          }
        `
      default:
        return ''
    }
  }}
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 666;
  width: 95%;
  opacity: 1;

  ${device.medium} {
    top: -26px;
    width: 100%;
  }
`
