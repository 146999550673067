import 'swiper/css'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { ICategoryOpenerExtended } from '../../types'
import { CategoryOpener } from './CategoryOpener'
import {
  BottomRow,
  ExtendedImages,
  GridContainer,
  ImageLink,
  ImageWrapper,
  SlideContainer,
  SliderContainer,
} from './CategoryOpenerExtended.styles'
import { SecondaryPost } from './SecondaryPost'
import { mediaCdnReplace } from '../../utils'

type Props = {
  categoryOpenerExt: ICategoryOpenerExtended
}

export const CategoryOpenerExtended = ({ categoryOpenerExt }: Props) => {
  const swiperProps: SwiperProps = {
    slidesPerView: 1.1,
    dir: 'rtl',
    loop: true,
    spaceBetween: 7,
    centeredSlides: true,
    breakpoints: {
      500: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2.2,
      },
      900: {
        slidesPerView: 2.5,
      },
      1100: {
        slidesPerView: 3.12,
      },
    },
  }

  const { extendedComp, posts } = categoryOpenerExt

  return (
    <CategoryOpener categoryOpener={categoryOpenerExt} lifestyle>
      <ExtendedImages>
        <SliderContainer>
          <Swiper {...swiperProps}>
            {extendedComp.map((extendedComp, index) => (
              <SwiperSlide key={index}>
                <SlideContainer>
                  <ImageLink href={extendedComp?.link}>
                    <img
                      loading='lazy'
                      src={mediaCdnReplace(extendedComp.img)}
                      alt='image'
                    />
                  </ImageLink>
                </SlideContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderContainer>
        <GridContainer>
          {extendedComp.map((extendedComp, index) => (
            <ImageWrapper key={index}>
              <ImageLink href={extendedComp.link}>
                <img
                  loading='lazy'
                  src={mediaCdnReplace(extendedComp.img)}
                  alt='image'
                />
              </ImageLink>
            </ImageWrapper>
          ))}
        </GridContainer>
      </ExtendedImages>
      <BottomRow>
        {posts.slice(1, 3).map((post, index) => {
          return <SecondaryPost key={index} post={post} lifestyle />
        })}
      </BottomRow>
    </CategoryOpener>
  )
}
