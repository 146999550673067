import { isNativeApp } from './isNativeApp'
import { size } from '../styles/GlobalVariables'

export function getAdDeviceType() {
  return window.innerWidth < size.regular
    ? isNativeApp()
      ? 'app'
      : 'mobile'
    : 'desktop'
}

export const isDesktop = () => getAdDeviceType() === 'desktop'

export const isMobile = () => !isDesktop()

export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent)
}
