import { LogoImage, LogoLink } from './Header/Header.styles'
import { assets } from '../styles/GlobalVariables'
import { he } from '../locales/he'
import { usePage } from '../contexts'
import { useMemo } from 'react'
import { mediaCdnReplace } from '../utils'

export const LogoComp = ({ inFooter }: { inFooter?: boolean }) => {
  const page = usePage()
  const logoMobile = useMemo(() => {
    if (page) {
      const { SiteMeta } = page
      if (SiteMeta.site_logo) {
        return SiteMeta.site_logo.replace(
          /upload\/*.v/,
          'upload/c_scale,h_25,f_auto,q_auto:best/v'
        )
      }
    }
    return assets.images.logoMobile
  }, [page])
  const logoDesktop = useMemo(() => {
    if (page) {
      const { SiteMeta } = page
      if (SiteMeta.site_logo) {
        return SiteMeta.site_logo.replace(
          /upload\/*.v/,
          'upload/c_scale,h_35,f_auto,q_auto:best/v'
        )
      }
    }
    return assets.images.logoDesktop
  }, [page])

  return (
    <LogoLink href='/' theme={{ inFooter: inFooter }}>
      <LogoImage
        loading='lazy'
        src={mediaCdnReplace(logoDesktop)}
        alt={he.logoAlt}
        theme={{ visible: 'desktop' }}
      />
      <LogoImage
        loading='lazy'
        src={mediaCdnReplace(logoMobile)}
        alt={he.logoAlt}
        theme={{ visible: 'mobile' }}
      />
    </LogoLink>
  )
}
