import { ReactElement, useEffect, useMemo, useState } from 'react'
import { iRecipePage } from '../../../types'
import { ArticleMeta, ArticleTitle } from '../../Article'
import ArticleTags from '../../Article/ArticleTags/ArticleTags'
import { ArticleVideo } from '../../ArticleVideo/ArticleVideo'
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs'
import { ImageWithCredits } from '../../ImageWithCredits/ImageWithCredits'
import { ShareButtons } from '../../ShareButtons/ShareButtons'
import {
  Container,
  Content,
  FullWidth,
  HorizontalContainer,
  RecipeTextContainer,
  ShareButtonsWrapper,
  Sidebar,
  TaboolaLR,
} from '../Article.styles'

import Cookies from 'universal-cookie'
import { useWindow } from '../../../contexts'
import { config } from '../../../lib'
import { parseHTML } from '../../ContentParser/ContentParser'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../../Taboola'
import {
  StyledMealSize,
  StyledPrepTime,
  StyledRatings,
  StyledRecipeHeader,
  StyledStar,
} from './Recipe.styles'
import { cleanHtmlString } from '../../ContentParser/utils'

type Props = {
  page: iRecipePage
}

function Ratings(ratings: { count: number; value: number; id: number }) {
  const cookies = new Cookies()
  const rated = cookies.get('rated_' + ratings.id)
  const [state, setValue] = useState({
    value: -1,
    rated: typeof rated !== 'undefined',
  })
  const handleClick = (val: number) => {
      if (state.rated) {
        return
      }
      fetch(config.dataQuerySource + '/Services/recipesRating.php', {
        method: 'POST',
        body: JSON.stringify({
          action: 'postratings',
          post_ID: ratings.id,
          rate: val,
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          cookies.set('rated_' + ratings.id, val + 1, {
            path: '/',
            expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
          })
          setValue({
            ...state,
            rated: true,
          })
        })
    },
    mouseEnter = (i: number) => {
      if (state.rated) {
        return
      }
      setValue({
        ...state,
        value: i,
      })
    }

  return (
    <StyledRatings>
      <div className={'title'}>דירוג הגולשים:</div>
      <div className={'stars'}>
        {Array.from(Array(5).keys()).map(i => {
          let star = 'empty'
          if (state.value > -1) {
            star = i <= state.value ? 'full_blue' : 'empty_blue'
          } else {
            if (i < Math.floor(ratings.value)) {
              star = 'full'
            } else {
              if (i + 1 === Math.ceil(ratings.value)) {
                star = 'half'
              }
            }
          }
          return (
            <StyledStar
              key={i}
              theme={{ star: star, rated: state.rated }}
              onClick={() => handleClick(i)}
              onMouseEnter={() => mouseEnter(i)}
              onMouseLeave={() => mouseEnter(-1)}
            />
          )
        })}
      </div>
    </StyledRatings>
  )
}

type RecipeHeaderProps = {
  mealSize: number
  ratings: { value: number; count: number }
  prepTime: number
  id: number
}
const RecipeHeader = ({
  mealSize,
  ratings,
  prepTime,
  id,
}: RecipeHeaderProps) => {
  return (
    <StyledRecipeHeader>
      <Ratings {...ratings} id={id} />
      <StyledPrepTime>
        <div className={'title'}>זמן הכנה:</div>
        <div className={'value'}>{prepTime}</div>
      </StyledPrepTime>
      <StyledMealSize>
        <div className={'title'}>כמות סועדים:</div>
        <div className={'value'}>{mealSize}</div>
      </StyledMealSize>
    </StyledRecipeHeader>
  )
}

export const Recipe = ({ page }: Props) => {
  const article = page.Content.Item
  const [parsedContent, setContent] = useState<ReactElement[][]>()

  const win = useWindow()

  // TODO: Remove blockquote workaround based on input from Reshet
  // See https://zemingo.atlassian.net/browse/RES-135
  const postContent = useMemo(
      () => cleanHtmlString(article.postContent || ''),
      [article.postContent]
    ),
    instructions = useMemo(
      () => cleanHtmlString(article.instructions),
      [article.instructions]
    ),
    ingredients = useMemo(
      () => cleanHtmlString(article.ingredients),
      [article.ingredients]
    )

  const reducedContent = [postContent, ingredients, instructions].reduce(
    (ctext, text) => {
      return ctext + cleanHtmlString(text || '')
    },
    ''
  )

  useEffect(() => {
    const content: ReactElement[][] = []
    content.push(parseHTML({ html: postContent }))
    content.push(
      parseHTML({
        html: ingredients,
        adMidArticle: false,
      })
    )
    content.push(
      parseHTML({
        html: instructions,
        adMidArticle: false,
      })
    )
    setContent(content)
  }, [postContent, ingredients, instructions])

  if (!article) {
    return null
  }

  const { PageMeta, Header, SEO } = page
  const {
    video,
    title,
    secondaryTitle,
    sponsorship,
    image,
    tags,
    prepTime,
    mealSize,
    ratings,
    id,
  } = article
  const articleBreadcrumbs = Header.breadCrumbs.slice(
    0,
    Header.breadCrumbs.length - 1
  )
  const disableTaboola = PageMeta.ads.block.taboola

  return (
    <Container>
      <HorizontalContainer>
        <Content>
          <Breadcrumbs breadcrumbs={articleBreadcrumbs} />
          <ArticleTitle title={title} subtitle={secondaryTitle} />
          {/* TODO: Replace commentNumber placeholder value */}
          <ArticleMeta
            credits={article}
            sponsorship={sponsorship}
            commentNumber={0}
            showComments={false}
          />
          <FullWidth>
            {video && video.kalturaId ? (
              <ArticleVideo
                pageMeta={PageMeta}
                video={{ ...video, poster: article.image.src }}
                hideDetails={false}
              />
            ) : (
              <ImageWithCredits image={image} showCredits={true} />
            )}
          </FullWidth>
          <RecipeHeader
            {...{
              ratings: ratings,
              mealSize: mealSize,
              prepTime: prepTime,
              id: id,
            }}
          />
          {article &&
            (win ? (
              <RecipeTextContainer>{parsedContent}</RecipeTextContainer>
            ) : (
              <RecipeTextContainer
                dangerouslySetInnerHTML={{ __html: reducedContent }}
              />
            ))}
          <ArticleTags tags={tags} />
        </Content>
        <Sidebar headerHeight={0} stickyShare={false}>
          <ShareButtonsWrapper>
            <ShareButtons SEO={SEO} size={'38px'} />
          </ShareButtonsWrapper>
          {win && win.innerWidth > 768 && !disableTaboola && (
            <TaboolaLR>
              <Taboola
                pageType={PocTaboolaPageType.recipe}
                suffixId={PocTaboolaSuffixId.recipeLeftRail}
              />
            </TaboolaLR>
          )}
        </Sidebar>
      </HorizontalContainer>
      {!disableTaboola && (
        <Taboola
          pageType={PocTaboolaPageType.recipe}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      )}
    </Container>
  )
}
export default Recipe
