const storageName = 'session_view'
const getFromStorage = () => sessionStorage.getItem(storageName)
export const setPageViewedInSession = (value: number) => {
  sessionStorage.setItem(storageName, value.toString())
}
export const initPageViewedInSession = () => {
  const counterString = getFromStorage()
  let counter = counterString && parseInt(counterString, 10)
  if (!counter) {
    counter = 0
  }
  setPageViewedInSession(counter + 1)
}
export const getPageViewedInSession = () => {
  const counterString = getFromStorage()
  const counter = counterString && parseInt(counterString, 10)

  if (counter) {
    return counter
  }

  setPageViewedInSession(0)
  return 0
}
