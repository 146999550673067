import React, { useEffect, useRef, useState } from 'react'
import { MarqueeContainer, Text, TextItem } from './PlayerMarqueTitle.styles'

interface MarqueeProps {
  text: string
  width: string
  color: string
  speed?: number
  className: string
  fontSize: number
  display: string
}

export const PlayerMarqueTitle = ({
  className,
  fontSize,
  text,
  width,
  color,
  speed = 50,
  display,
}: MarqueeProps) => {
  const marqueeRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const [shouldScroll, setShouldScroll] = useState(false)

  const updateScrollState = () => {
    const marqueeElement = marqueeRef.current
    const textElement = textRef.current?.querySelector('.textItem')

    if (marqueeElement && textElement) {
      setShouldScroll(textElement.scrollWidth > marqueeElement.clientWidth)
    }
  }

  useEffect(() => {
    const textElement = textRef.current

    if (textElement) {
      const resizeObserver = new ResizeObserver(() => {
        updateScrollState()
      })
      resizeObserver.observe(textElement)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [text])

  return (
    <MarqueeContainer
      theme={{ display: display, width: width }}
      className={className}
      ref={marqueeRef}
    >
      <Text
        ref={textRef}
        fontSize={fontSize}
        color={color}
        shouldScroll={shouldScroll}
        speed={speed}
        onMouseEnter={() => {
          if (shouldScroll && textRef.current) {
            textRef.current.style.animationPlayState = 'paused'
          }
        }}
        onMouseLeave={() => {
          if (shouldScroll && textRef.current) {
            textRef.current.style.animationPlayState = 'running'
          }
        }}
      >
        {Array.from(Array(shouldScroll ? 5 : 1).keys()).map(i => (
          <TextItem className={'textItem'} key={i}>
            {text}
          </TextItem>
        ))}
      </Text>
    </MarqueeContainer>
  )
}
export default PlayerMarqueTitle
