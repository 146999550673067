import React, { useMemo } from 'react'
import {
  IImage,
  ILiveVodPage,
  ILiveVodPageGrid,
  isLiveBlock,
} from '../../types'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import {
  LiveVodContainer,
  LiveVodContainerBg,
  TaboolaWidgetWrapper,
  TaboolaWrapper,
} from './LiveVod.styles'
import { PageGridItemToComponent } from '../itemToComponent'
import { EditMe } from '../EditMe'

type Props = {
  page: ILiveVodPage
}

const defaultBackground: { imageObj: IImage } = {
  imageObj: {
    d: 'https://media3.reshet.tv/image/upload/q_auto/v1652191842/uploads/2022/903039891.webp',
    m: 'https://media3.reshet.tv/image/upload/q_auto/v1652617359/uploads/2022/903048309.webp',
    alt_d: 'Frame 955',
    alt_m: 'Frame 955',
    lazy: 'https://media3.reshet.tv/image/upload/t_lazy_loader/v1652191842/uploads/2022/903039891.webp',
  },
}
export const LiveVod = ({ page: { PageMeta, Content } }: Props) => {
  const disableTaboola = PageMeta.ads.block.taboola

  const items = useMemo(() => {
    const totalBlocks = Content.PageGrid.length
    let taboolaCount = 1
    return Content.PageGrid.map((block, index) => {
      let taboolaBlock = null
      if (!disableTaboola) {
        if (index === 0) {
          taboolaBlock = (
            <TaboolaWidgetWrapper>
              <Taboola
                pageType={PocTaboolaPageType.live}
                suffixId={PocTaboolaSuffixId.widget}
              />
            </TaboolaWidgetWrapper>
          )
        } else if (index > 0 && index % 5 === 0 && index + 1 < totalBlocks) {
          taboolaBlock = (
            <Taboola
              key={`midPageWidget${taboolaCount}`}
              pageType={PocTaboolaPageType.live}
              suffixId={PocTaboolaSuffixId.midPageWidget}
              index={taboolaCount++}
            />
          )
        }
      }
      return (
        <React.Fragment key={'fragment-block' + index}>
          <EditMe
            key={'edit-me-' + index}
            grid_id={block.grid_id}
            children={PageGridItemToComponent(block, PageMeta, index)}
          />
          {taboolaBlock}
        </React.Fragment>
      )
    })
  }, [Content.PageGrid, PageMeta, disableTaboola])

  const background = useMemo(() => {
    let background = defaultBackground
    for (const grid in Content.PageGrid) {
      if (isLiveBlock(Content.PageGrid[grid] as ILiveVodPageGrid)) {
        background = Content.PageGrid[grid].background
      }
    }
    return background.imageObj
  }, [Content.PageGrid])

  return (
    <LiveVodContainerBg background={background}>
      <LiveVodContainer>
        {items}
        {!disableTaboola && (
          <TaboolaWrapper>
            <Taboola
              pageType={PocTaboolaPageType.live}
              suffixId={PocTaboolaSuffixId.pageFooter}
            />
          </TaboolaWrapper>
        )}
      </LiveVodContainer>
    </LiveVodContainerBg>
  )
}
