import dynamic from 'next/dynamic'
import React, { createContext, useEffect, useState } from 'react'
import { utils } from '../../components/Player/Helpers'

interface IDebuggerContext {
  log: string[]
  setLog: React.Dispatch<React.SetStateAction<string[]>>
}

const defaultState = {
  log: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLog: () => {},
} as IDebuggerContext

interface IDebuggerProps {
  children: React.ReactNode
}

export const DebuggerContext = createContext(defaultState)
export const DebuggerProvider = ({ children }: IDebuggerProps) => {
  const [log, setLog] = useState<string[]>([])
  const [show, setShow] = useState(false)
  const Debugger = dynamic(() => import('../../components/Debugger/Debugger'), {
    ssr: false,
  })

  useEffect(() => {
    const shouldShow =
      utils.getParameterByName('showDebug') === 'true' ||
      !!sessionStorage.getItem('showDebug')
    shouldShow && sessionStorage.setItem('showDebug', 'true')
    setShow(shouldShow)
  }, [])

  return (
    <DebuggerContext.Provider value={{ log, setLog }}>
      {children}
      {show && <Debugger />}
    </DebuggerContext.Provider>
  )
}
