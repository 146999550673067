import { DateTime } from 'luxon'
import { he } from '../../locales/he'
import { IBroadcastWeek } from '../../types'
import {
  Container,
  HorizontalOverlay,
  Hour,
  PosterWrapper,
  Show,
  ShowsContainer,
  TextContainer,
  Title,
  TodayAtReshet,
  Weekday,
} from './FeaturedShows.styles'
import { mediaCdnReplace } from '../../utils'

type Props = {
  featuredShows: IBroadcastWeek[]
}

export const FeaturedShows = ({ featuredShows }: Props) => {
  return (
    <Container>
      <ShowsContainer>
        {featuredShows.map(
          (
            { primePoster, weekday, shortDate, primeShow: primeShowIdx, shows },
            index
          ) => {
            const today = DateTime.local({ zone: 'Israel' }).toFormat('dd.MM')
            const isToday = today === shortDate
            const isTomorrow =
              today ===
              DateTime.fromFormat(shortDate, 'dd.MM')
                .minus({ days: 1 })
                .toFormat('dd.MM')

            let displayDate

            if (isToday) {
              displayDate = he.today
            } else if (isTomorrow) {
              displayDate = he.tomorrow
            } else {
              displayDate = weekday
            }

            const primeShow = shows?.[primeShowIdx]
            if (!primeShow) {
              return
            }

            return (
              <Show key={`show-${index}`}>
                {index === 0 && (
                  <TodayAtReshet>{he.todayAtReshet13}</TodayAtReshet>
                )}
                {primePoster && (
                  <PosterWrapper href={primeShow.link}>
                    <img
                      loading='lazy'
                      src={mediaCdnReplace(primePoster)}
                      alt={primeShow.title}
                    />
                  </PosterWrapper>
                )}
                <TextContainer href={primeShow.link}>
                  <span>
                    <Weekday>{displayDate}</Weekday>
                    <Hour>{primeShow.start_time}</Hour>
                  </span>
                  <Title>{primeShow.title}</Title>
                </TextContainer>
              </Show>
            )
          }
        )}
      </ShowsContainer>
      <HorizontalOverlay />
    </Container>
  )
}
