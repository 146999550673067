import { useMemo } from 'react'
import { he } from '../../locales/he'
import { IImageWithCredits } from '../../types'
import { ZoomIn } from '../ZoomIn'
import { Container, Details, ImageWrapper } from './ImageWithCreditsFl.styles'
import { mediaCdnReplace } from '../../utils'

type Props = {
  image: IImageWithCredits
  showCredits: boolean
  isInTimeline?: boolean
  isLazy?: boolean
}

export const ImageWithCreditsFl = ({
  image,
  showCredits = true,
  isInTimeline,
  isLazy = true,
}: Props) => {
  const src = useMemo(
    () => mediaCdnReplace(image.src.replace(/png|jpg|jpeg/g, 'webp')),
    [image.src]
  )

  return (
    image && (
      <Container>
        <ImageWrapper className='article-image'>
          <img loading={isLazy ? 'lazy' : 'eager'} src={src} alt={image.alt} />
          {!isInTimeline && <ZoomIn index={0} />}
        </ImageWrapper>
        {showCredits && image.description && image.credit && (
          <Details>{`${image.description}. ${he.photoBy}: ${image.credit}`}</Details>
        )}
      </Container>
    )
  )
}
