export const Spinner = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM3.00753 20C3.00753 29.3847 10.6153 36.9925 20 36.9925C29.3847 36.9925 36.9925 29.3847 36.9925 20C36.9925 10.6153 29.3847 3.00753 20 3.00753C10.6153 3.00753 3.00753 10.6153 3.00753 20Z'
        fill='#D9D9D9'
      />
      <path
        d='M40 20C40 17.3736 39.4827 14.7728 38.4776 12.3463C37.4725 9.91982 35.9993 7.71504 34.1421 5.85786C32.285 4.00069 30.0802 2.5275 27.6537 1.52241C25.2272 0.517315 22.6264 -1.14805e-07 20 0V3.00753C22.2315 3.00753 24.4411 3.44706 26.5027 4.30101C28.5644 5.15496 30.4376 6.40662 32.0155 7.98451C33.5934 9.56241 34.845 11.4356 35.699 13.4973C36.5529 15.5589 36.9925 17.7685 36.9925 20H40Z'
        fill='#00104A'
      />
      <circle cx='19.9745' cy='1.51282' r='1.51282' fill='#00104A' />
      <circle cx='38.4616' cy='19.9994' r='1.53846' fill='#00104A' />
    </svg>
  )
}
export const Logo = () => {
  return (
    <svg
      width='84'
      height='118'
      viewBox='0 0 84 118'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_15005_382994)'>
        <path d='M76.2461 8L60.0098 24.2514H76.2461V8Z' fill='#FFF760' />
      </g>
      <g filter='url(#filter1_d_15005_382994)'>
        <path
          d='M76.2461 8H24.3844V24.2514H60.0098L76.2461 8Z'
          fill='url(#paint0_linear_15005_382994)'
        />
      </g>
      <g filter='url(#filter2_d_15005_382994)'>
        <path
          d='M60.0098 24.2531L43.8322 40.4581C42.7298 41.5623 42.7298 43.3502 43.8322 44.4513L53.309 53.9442L76.2461 30.9683V24.25H60.0098V24.2531Z'
          fill='#2EFBEE'
        />
      </g>
      <path
        d='M3.578 87.9083H20.2396V8H7.06582C5.1389 8.00625 3.578 9.61772 3.578 11.6071V87.9051V87.9083Z'
        fill='#000721'
      />
      <path
        d='M58.083 53.4285C59.8987 55.2813 61.0196 57.8207 61.0196 60.62C61.0196 66.296 56.4248 70.8986 50.7584 70.8986C45.0921 70.8986 40.6393 66.4321 40.5034 60.8737H23.5229C23.6588 75.8259 35.8005 87.9047 50.7584 87.9047C65.7163 87.9047 77.997 75.6898 77.997 60.62C77.997 53.1223 74.9739 46.3328 70.0888 41.4023L58.083 53.4285Z'
        fill='#000721'
      />
      <path
        d='M76.2461 8H24.3844V24.2514H60.0098L76.2461 8Z'
        fill='url(#paint1_linear_15005_382994)'
      />
      <path
        d='M76.2461 8L60.0098 24.2514H76.2461V8Z'
        fill='url(#paint2_linear_15005_382994)'
      />
      <g clipPath='url(#clip0_15005_382994)'>
        <path
          d='M50.2693 94.8281V109.519C50.2693 110.996 49.0602 112.197 47.5727 112.197H44.9489V94.8438L38.7108 94.8281V112.197H33.4537V94.8438L27.2156 94.8281V117.715L48.0886 117.721C52.7316 117.721 56.5106 113.964 56.5106 109.352V94.8438L50.2724 94.8281H50.2693Z'
          fill='#000721'
        />
        <path
          d='M75.4847 94.8281H58.9255V100.358H71.2848V117.711H77.5229V96.8558C77.5229 95.7398 76.6083 94.8313 75.4847 94.8313V94.8281Z'
          fill='#000721'
        />
        <path
          d='M22.522 94.8266L3.44982 94.8203C3.43716 98.2061 3.44349 107.839 3.44349 111.224C3.44349 111.784 3.32639 111.891 2.7472 111.888H0V117.675C1.44639 117.663 3.31373 117.688 4.76013 117.657C5.21272 117.647 5.6748 117.556 6.10841 117.424C7.90928 116.877 9.87156 114.83 9.81143 112.252V100.36H18.3221V117.713H24.5602V96.8543C24.5602 95.7383 23.6455 94.8297 22.522 94.8297V94.8266Z'
          fill='#000721'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_15005_382994'
          x='52.8382'
          y='0.828446'
          width='30.5794'
          height='30.5931'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='3.58578' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.376471 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_15005_382994'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_15005_382994'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_15005_382994'
          x='17.2128'
          y='0.828446'
          width='66.2048'
          height='30.5931'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='3.58578' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.909804 0 0 0 0 0.211765 0 0 0 0 0.67451 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_15005_382994'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_15005_382994'
            result='shape'
          />
        </filter>
        <filter
          id='filter2_d_15005_382994'
          x='35.8338'
          y='17.0784'
          width='47.5838'
          height='44.0384'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='3.58578' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.180392 0 0 0 0 0.984314 0 0 0 0 0.933333 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_15005_382994'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_15005_382994'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_15005_382994'
          x1='24.6827'
          y1='24.6476'
          x2='75.7701'
          y2='7.97452'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D51896' />
          <stop offset='1' stopColor='#FF5CC7' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_15005_382994'
          x1='24.6827'
          y1='24.6476'
          x2='75.7701'
          y2='7.97452'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D51896' />
          <stop offset='1' stopColor='#FF5CC7' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_15005_382994'
          x1='76.3338'
          y1='16.8787'
          x2='60.2327'
          y2='24.6582'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFF760' />
          <stop offset='1' stopColor='#FFF100' />
        </linearGradient>
        <clipPath id='clip0_15005_382994'>
          <rect
            width='77.5229'
            height='22.8991'
            fill='white'
            transform='translate(0 94.8242)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
