import { useMemo } from 'react'
import { AdId, useGridBlock } from '../../../contexts'
import { ILayout6 } from '../../../types'
import Ad from '../../Ads/Ad'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Container, PostContainer, Poster, StyledPost } from './Layout6.styles'

type Props = {
  layout: ILayout6
}

export const Layout6 = ({ layout }: Props) => {
  const posts = useMemo(() => layout.posts.slice(0, 6), [layout.posts]) // Only keep the first 6 posts
  const { grid_title, adUnit, layout_mobile } = layout
  const { gridId } = useGridBlock()

  return posts ? (
    <Container key={gridId + 'Layout6'} className={'shlomy'}>
      <ComponentTitle componentTitle={grid_title} />
      <PostContainer>
        {posts.map((post, index) => (
          <StyledPost
            key={index + '-' + post.id}
            layoutMobile={layout_mobile}
            post={post}
            height='307px'
            maxLines={3}
          />
        ))}
        <Poster key={gridId + 'poster'}>
          <Ad id={adUnit as AdId} />
        </Poster>
      </PostContainer>
    </Container>
  ) : null
}
