import { useState } from 'react'
import { ImgWithRatio } from './Styles/Player.styles'
import { mediaCdnReplace } from '../../utils'

type Props = {
  src: string
}
export const Copyrights = ({ src }: Props) => {
  const [info, setInfo] = useState(mediaCdnReplace(src))
  return (
    <ImgWithRatio
      loading='lazy'
      src={mediaCdnReplace(info)}
      alt={'תוכן אינו זמין'}
      onError={() =>
        setInfo(
          'https://media3.reshet.tv/image/upload/c_scale,f_auto,q_auto:good,w_1288/v1530520728/unavilable_kbpk4u.jpg'
        )
      }
    />
  )
}
