import { useMemo } from 'react'
import { AdId } from '../../../contexts'
import Ad from '../Ad'
import { Container } from './MidBox.styles'

export interface IProps {
  index: number
  collapse: boolean
  onRender?: (ev: googletag.events.SlotRenderEndedEvent) => void
}

export const MidBox = ({ index, collapse, onRender }: IProps) => {
  const id = useMemo(() => index && (`Mid_box_${index}` as AdId), [index])

  if (!id) {
    return null
  }
  return (
    <Container data-name={id} key={id}>
      <Ad
        id={id}
        collapse={collapse}
        // sizeMap={[
        //   {
        //     screen: [375, 0],
        //     ad: [336, 280],
        //   },
        //   {
        //     screen: [340, 0],
        //     ad: [300, 250],
        //   },
        // ]}
        onRender={onRender}
      />
    </Container>
  )
}
export default MidBox
