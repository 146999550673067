import styled from 'styled-components'
import {
  colors,
  device,
  themeClassName,
  zIndex,
} from '../../../styles/GlobalVariables'
import AdClose from '/public/assets/images/svg/ad-close.svg'

export const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  background: ${colors.white};
  z-index: ${zIndex.footerStrip};

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    background: ${colors.tvGuideDark};
  }

  ${device.medium} {
    align-items: center;
  }
`

export const Content = styled.div`
  position: relative;
`

export const AdCloseIcon = styled(AdClose)`
  font-size: 19px;
  position: relative;
  margin-left: 4px;
  z-index: 1;
  cursor: pointer;

  ${device.medium} {
    font-size: 38px;
    position: absolute;
    right: 45px;
  }
`
