import { IImageCounter } from '../../types'
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CounterUp } from './Counters/CounterUp'
import { CounterDown } from './Counters/CounterDown'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { useRef, useState } from 'react'
import Link from '../Link/Link'

import {
  ButtonContainer,
  Image,
  ImageNameContainer,
  Name,
  SingleSlideWrapper,
  SliderWrapper,
  SubTitle,
  Text,
  TextContainer,
  Title,
  TitleSubTitleContainer,
  Wrapper,
} from './ImageCounter.styles'
import { NavButton } from './NavButton'
import { mediaCdnReplace } from '../../utils'

type Props = {
  image_counter: IImageCounter
}

export const ImageCounter = ({
  image_counter: {
    /*grid_id,                                                                                                                                                                     grid_type,*/
    counter_start_date,
    desktop_background,
    mobile_background,
    title,
    image_counters,
    counter_item_counter_up_down,
    counter_item_images_background_color,
    autoplay_time,
  },
}: Props) => {
  type Image = {
    name: string
    age: string
    text: string
    image: string
    url: string
    id: string
  }
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const images = image_counters
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return image_counters || title ? (
    <Wrapper
      theme={{
        desktop_background: mediaCdnReplace(desktop_background),
        mobile_background: mediaCdnReplace(mobile_background),
      }}
    >
      <SliderWrapper
        theme={{
          background_color: counter_item_images_background_color,
        }}
      >
        <ButtonContainer>
          <NavButton
            role={'prev'}
            ref={navigationPrevRef}
            swiperRef={swiperRef}
          />
          <NavButton
            role={'next'}
            ref={navigationNextRef}
            swiperRef={swiperRef}
          />
        </ButtonContainer>
        <Swiper
          autoplay={{
            delay: (autoplay_time || 3) * 1000,
          }}
          loop={true}
          allowTouchMove={true}
          modules={[Navigation, Pagination, Autoplay]}
          onSwiper={setSwiperRef}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
        >
          {images.map((image: Image, idx: number) => (
            <SwiperSlide key={idx}>
              <SingleSlideWrapper
                theme={{
                  background_color: counter_item_images_background_color,
                }}
              >
                <ImageNameContainer>
                  <Image src={mediaCdnReplace(image.image)}></Image>
                  {image.url ? (
                    <Link className={'nameLink'} href={image.url ?? '#'}>
                      <Name>{image.name}</Name>
                    </Link>
                  ) : (
                    <Name>{image.name}</Name>
                  )}
                </ImageNameContainer>
                {image.url ? (
                  <Link className={'textLink'} href={image.url ?? '#'}>
                    <Text>{image.text}</Text>
                  </Link>
                ) : (
                  <Text>{image.text}</Text>
                )}
              </SingleSlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderWrapper>
      {title && (
        <TextContainer>
          <TitleSubTitleContainer>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <SubTitle>חטופים</SubTitle>
          </TitleSubTitleContainer>
          {counter_item_counter_up_down == 'counter_up' ? (
            <CounterUp startDate={new Date(counter_start_date)} />
          ) : (
            <CounterDown targetDate={counter_start_date} />
          )}
        </TextContainer>
      )}
    </Wrapper>
  ) : null
}
