import { default as NextLink } from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { colors } from '../../styles/GlobalVariables'
import {
  IComponentTitle,
  isLogoTitle,
  isNoTitle,
  isTextTitle,
} from '../../types'
import { addAnalyticsToUrl, mediaCdnReplace } from '../../utils'
import Link from '../Link/Link'
import {
  Container,
  LeftText,
  Logo,
  Rainbow,
  Title,
} from './ComponentTitle.styles'
import LeftArrowIcon from '/public/assets/images/svg/left-arrow.svg'
import { useGridBlock, usePage } from '../../contexts'

type Props = {
  componentTitle: IComponentTitle
  className?: string
  pageId?: number
  columnSpan?: number
}

const ComponentTitle = ({
  componentTitle,
  pageId,
  columnSpan,
  className,
}: Props) => {
  const { gridId } = useGridBlock()
  const containerRef = useRef<HTMLDivElement>(null)
  const leftTextRef = useRef<HTMLAnchorElement>(null)
  const [containerWidth, setContainerWidth] = useState<number | null>(null)
  const [leftTextWidth, setLeftTextWidth] = useState<number | null>(null)
  const { link, leftText, color } = componentTitle
  const page = usePage()
  const isDarkTheme = page?.isDarkTheme || false
  const url =
    link &&
    addAnalyticsToUrl(link || '', pageId?.toString() || '', gridId || '')
  let rainbow = <></>
  if (!isDarkTheme) {
    rainbow =
      !!url && !!leftText && !!leftTextWidth ? (
        <Rainbow
          lineColor={color}
          leftTextWidth={leftTextWidth}
          renderRainbow={color.toLowerCase() === colors.darkBlue.toLowerCase()}
        />
      ) : (
        <Rainbow
          leftTextWidth={100}
          lineColor={color}
          renderRainbow={color.toLowerCase() === colors.darkBlue.toLowerCase()}
        />
      )
  }

  useEffect(() => {
    if (!containerRef.current || !leftTextRef.current) {
      return
    }
    setContainerWidth(containerRef.current.offsetWidth)
    setLeftTextWidth(leftTextRef.current.offsetWidth)
  }, [])

  if (
    isNoTitle(componentTitle) ||
    (isLogoTitle(componentTitle) && !componentTitle.logo) ||
    (isTextTitle(componentTitle) && !componentTitle.text)
  ) {
    return null
  }

  return (
    <Container
      ref={containerRef}
      className={className}
      containerWidth={containerWidth}
      leftTextWidth={leftTextWidth}
      lineColor={color}
      renderRainbow={color.toLowerCase() === colors.darkBlue.toLowerCase()}
      columnSpan={columnSpan}
      isDarkTheme={isDarkTheme}
    >
      {isTextTitle(componentTitle) &&
        (url ? (
          <Link href={url}>
            <Title isDarkTheme={isDarkTheme}>{componentTitle.text}</Title>
          </Link>
        ) : (
          <Title isDarkTheme={isDarkTheme}>{componentTitle.text}</Title>
        ))}
      {isLogoTitle(componentTitle) &&
        (url ? (
          <Link href={url}>
            {componentTitle.logo && (
              <Logo>
                {/* Check why natural image won't take styling on iPhone */}
                <img
                  loading='lazy'
                  src={mediaCdnReplace(componentTitle.logo)}
                  alt='logo'
                />
              </Logo>
            )}
          </Link>
        ) : (
          componentTitle.logo && (
            <Logo>
              {/* Check why natural image won't take styling on iPhone */}
              <img
                loading='lazy'
                src={mediaCdnReplace(componentTitle.logo)}
                alt='logo'
              />
            </Logo>
          )
        ))}
      {!!url && (
        <NextLink href={url} passHref>
          <LeftText color={color} ref={leftTextRef} isDarkTheme={isDarkTheme}>
            <LeftArrowIcon />
            <span>{leftText}</span>
          </LeftText>
        </NextLink>
      )}
      {rainbow}
    </Container>
  )
}

export default ComponentTitle
