import styled, { css } from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

const defaultStyle = css``

export const Container = styled.div<{ rendered?: boolean }>`
  ${({ rendered }) => rendered && defaultStyle}
  ${device.small} {
    display: none;
  }
`
