import dynamic from 'next/dynamic'
import { usePage } from '../../contexts'
import { useFL } from '../../hooks/useFL'
import { colors } from '../../styles/GlobalVariables'
import { IPost } from '../../types'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import { utils } from '../Player/Helpers'
import { Shave } from '../Shave/Shave'
import {
  Container,
  PromoStrip,
  TextContainer,
  Thumbnail,
  Title,
} from './Post.styles'
import { PlayButton } from '../../icons'
// import PlayButton from '/public/assets/images/svg/play-button-fl.svg'

type Props = {
  post: IPost
  height?: string
  className?: string
  maxLines?: number
  grid_id?: number
}

export const Post = ({ post, className, grid_id }: Props) => {
  const {
    redStrip,
    imageObj,
    title,
    isBlank,
    promotedStripText,
    video,
    link: postLink,
  } = post

  const linkTarget = isBlank ? '_blank' : undefined
  const page = usePage()
  const pageId = page?.PageMeta.id
  const { fontColor, isDarkTheme } = useFL()
  const queryParams = utils.addPageQueryParams(postLink, pageId, grid_id)
  const link = postLink + queryParams
  const hide_date = !postLink.match(/^(\/item)/)
  const RedStrip = dynamic(() => import('../RedStrip/RedStrip'))
  return (
    <Container className={className}>
      <Thumbnail href={link} target={linkTarget}>
        {redStrip && <RedStrip {...redStrip} />}
        <Image image={imageObj} alt={title} />
        {promotedStripText && <PromoStrip>{promotedStripText}</PromoStrip>}
        <PlayButton show={!!video} />
      </Thumbnail>
      <TextContainer>
        <Link href={link} target={linkTarget}>
          <Shave maxLines={2}>
            <Title fontColor={fontColor}>{title}</Title>
          </Shave>
        </Link>
        <Credits
          credits={post}
          color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
          hide_date={hide_date}
        />
      </TextContainer>
    </Container>
  )
}
