import { clientConfig } from '../client/clientConfig'
import { IPageProps } from '../types'

export const setClientConfig = (props: IPageProps) => {
  clientConfig.newsFlashQuerySource = props.newsFlashQuerySource
  clientConfig.baseUrl = props.baseUrl
  clientConfig.googleTagManager = props.googleTagManager
  clientConfig.googleAnalytics = props.googleAnalytics
  clientConfig.kalturaPlayerId = props.kalturaPlayerId || '48907342'
  clientConfig.disableMetrics = props.disableMetrics || '0'
  clientConfig.live_ima = props.live_ima || 'enabled'
  clientConfig.live_mid_rolls = props.live_mid_rolls || 'enabled'
  clientConfig.dataQuerySource = props.dataQuerySource || ''
  clientConfig.geo_plugin_key = props.geo_plugin_key || ''
  clientConfig.geo_ttl = props.geo_ttl || 4
  clientConfig.kaltura_end_point =
    props.kaltura_end_point ||
    'https://5031.frp1.ott.kaltura.com/api_v3/service/'
  clientConfig.login_page = props.login_page
  clientConfig.bigBrotherRegistration = props.bigBrotherRegistration
  clientConfig.adTargetEnv = props.adTargetEnv || 'prod'
  clientConfig.liveSources = props.liveSources
  clientConfig.configFile = props.configFile
}
