import { ITimelineItem } from '../../../types'
import { DateTime, mediaCdnReplace } from '../../../utils'
import { utils } from '../../Player/Helpers/utils'
import {
  Container,
  ContentContainer,
  Image,
  SecondaryTitle,
  TextContainer,
  Time,
  TimeContainer,
  Title,
} from './TimeLineDesktop.styles'
import { filterContent } from './utils'

type Props = {
  fontColor?: string
  gid?: number
  pId?: number
  timeline: ITimelineItem
}

const TimeLineDesktop = ({ fontColor, gid, pId, timeline }: Props) => {
  const { timelineUrl, post_title, createdOn, image, post_content } = timeline
  const queryParams = utils.addPageQueryParams(timelineUrl, pId, gid)
  const link = timelineUrl + queryParams
  return (
    <Container href={link}>
      <ContentContainer>
        <TimeContainer>
          <Time>{DateTime.parse(createdOn)?.toTime()}</Time>
        </TimeContainer>
        {image && <Image src={mediaCdnReplace(image)} alt={post_title} />}
      </ContentContainer>
      <TextContainer>
        <Title fontColor={fontColor}>{post_title}</Title>
        <SecondaryTitle fontColor={fontColor}>
          {filterContent(post_content)}
        </SecondaryTitle>
      </TextContainer>
    </Container>
  )
}

export default TimeLineDesktop
