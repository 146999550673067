import Link from 'next/link'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ITimelineBlock, ITimelineItem } from '../../../types'
import { utils } from '../../Player/Helpers/utils'
import {
  DateElement,
  TimeLineElement,
  TimeLineElementInWrap,
  TimeLineElementsWrap,
  TimeLineHead,
  TimeLineWrapper,
  TitleElement,
} from './Style'

type TimeLineHeadStripType = {
  data: {
    title: string
    designedDesktopTitle?: string
    designedMobileTitle?: string
    link?: string
    favcolor?: string
    isCustomStyleEnabled?: boolean
    timeLineTitleColor?: string
  }
}
const TimeLineHeadStrip = ({ data }: TimeLineHeadStripType) => {
  const {
    title,
    link,
    designedDesktopTitle,
    designedMobileTitle,
    timeLineTitleColor,
  } = data

  if (!title) {
    return null
  } // || !(designedDesktopTitle && designedMobileTitle)
  return (
    <Link href={link ?? '#'}>
      <TimeLineHead
        theme={{
          hasLink: !!link,
          bg_desktop: designedDesktopTitle,
          bg_mobile: designedMobileTitle,
          titleColor: timeLineTitleColor,
        }}
      >
        {title}
      </TimeLineHead>
    </Link>
  )
}

const TimeLineInside = (
  el: ITimelineItem,
  inWrap?: string,
  timeLineTitleColor?: string
) => {
  const createdOn = el.createdOn.split(' ')[1].split(':')
  return (
    <TimeLineElementInWrap theme={{ inWrap: inWrap }}>
      <DateElement
        theme={{ bgColor: timeLineTitleColor }}
        children={[createdOn[0], createdOn[1]].join(':')}
      />
      <TitleElement children={el.post_title} />
    </TimeLineElementInWrap>
  )
}

export const TimeLineBlock = ({ timeline }: ITimelineBlock) => {
  const [timelineElComponent, setTimelineElComponent] = useState<
    unknown | null
  >(null)
  useEffect(() => {
    if (utils.isMobile()) {
      const swiperProps = {
        slidesPerView: 1.63,
        dir: 'rtl',
        spaceBetween: 10,
        breakpoints: {
          768: {
            slidesPerView: timeline.show,
          },
        },
      }
      setTimelineElComponent(
        <Swiper {...swiperProps}>
          {timeline.elements.slice(0, timeline.show).map((el, i) => {
            return (
              <SwiperSlide key={i}>
                <Link href={el.timelineUrl + '#' + el.id} scroll={false}>
                  <TimeLineElement
                    theme={{ show: timeline.show, img: el.image }}
                  >
                    {TimeLineInside(
                      el,
                      'in-wrap',
                      timeline.header.timeLineTitleColor
                    )}
                  </TimeLineElement>
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )
    } else {
      setTimelineElComponent(
        timeline.elements.slice(0, timeline.show).map((el, i) => {
          return (
            <Link href={el.timelineUrl + '#' + el.id} scroll={false} key={i}>
              <TimeLineElement
                theme={{
                  show: timeline.show,
                  img: el.image,
                  timeLineColor: timeline.header.timeLineTitleColor,
                }}
              >
                {TimeLineInside(el, '', timeline.header.timeLineTitleColor)}
              </TimeLineElement>
            </Link>
          )
        })
      )
    }
  }, [timeline.show, timeline.elements, timeline.header.timeLineTitleColor])

  return (
    <TimeLineWrapper>
      <TimeLineHeadStrip data={timeline.header} />
      <TimeLineElementsWrap>{timelineElComponent}</TimeLineElementsWrap>
    </TimeLineWrapper>
  )
}
export default TimeLineBlock
