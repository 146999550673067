import styled, { css } from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const RelativeDiv = styled.div`
  ${props =>
    props.theme.isAdmin
      ? css`
          position: relative;

          &:hover {
            [class*='StyledBtn'] {
              opacity: 0.5;

              &:hover {
                opacity: 1;
              }
            }
          }
        `
      : ''}
`

export const HiddenInput = styled.input``

export const ControlsWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  flex-direction: row-reverse;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
`

export const StyledBtn = styled.div`
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;

  background-image: url(${props =>
    props.theme.icon === 'link'
      ? 'https://media3.reshet.tv/image/upload/v1662367203/assets/2022/CopyLink_Icon.png'
      : 'https://media3.reshet.tv/image/upload/v1662367899/assets/2022/CopyImage_Icon.png'});
  background-repeat: no-repeat;
  opacity: 0.1;
  transition: opacity 0.3s ease;
`

export const MobileImage = styled.img`
  display: block;
  ${device.medium} {
    display: none;
    min-height: inherit;
    max-height: inherit;
  }
`

export const DesktopImage = styled.img`
  display: none;

  ${device.medium} {
    display: block;
    min-height: inherit;
    max-height: inherit;
  }
`
