import { useMemo } from 'react'
import { useAdminCookie } from '../../contexts'
import { IImage } from '../../types'
import { CopyImage, CopyLink } from './Copy'
import {
  ControlsWrap,
  DesktopImage,
  MobileImage,
  RelativeDiv,
} from './Image.styles'
import { mediaCdnReplace } from '../../utils'

type Props = {
  image: IImage
  alt?: string | undefined
  refDesktop?: React.Ref<HTMLImageElement>
  link?: URL | string
  disableControls?: boolean
  forAdmin?: boolean
  eager?: boolean
}
const defaultImageSrc =
    'https://media3.reshet.tv/image/upload/f_auto,q_auto:good/v1633341900/assets/2021/default_image_tv.webp',
  defaultImage: IImage = {
    d: defaultImageSrc,
    alt_d: 'רשת 13',
    m: defaultImageSrc,
    alt_m: 'רשת 13',
  }

export const Image = ({
  image,
  alt,
  refDesktop,
  link,
  disableControls,
  forAdmin = true,
  eager = false,
}: Props) => {
  const admin_cookie = useAdminCookie()

  image = image || defaultImage

  const desktop = useMemo(() => {
    return {
      src: mediaCdnReplace(image.d || '').replace(/png|jpg|jpeg/g, 'webp'),
      alt: image.alt_d,
      fallback: image.d || '',
    }
  }, [image.alt_d, image.d])

  const mobile = useMemo(() => {
    return {
      src: mediaCdnReplace(image.m || '').replace(/png|jpg|jpeg/g, 'webp'),
      alt: image.alt_m,
      fallback: image.m || '',
    }
  }, [image.alt_m, image.m])

  if (!image) {
    return null
  }

  const main = (
    <>
      {desktop.src && (
        <DesktopImage
          loading={eager ? 'eager' : 'lazy'}
          src={mediaCdnReplace(desktop.src)}
          alt={alt || desktop.alt}
          ref={refDesktop}
        />
      )}
      {mobile.src && (
        <MobileImage
          loading={eager ? 'eager' : 'lazy'}
          src={mediaCdnReplace(mobile.src)}
          alt={alt || mobile.alt}
        />
      )}
    </>
  )

  if (admin_cookie && forAdmin) {
    return (
      <RelativeDiv theme={{ isAdmin: admin_cookie && !disableControls }}>
        {main}
        {!disableControls && admin_cookie && (
          <ControlsWrap>
            {desktop.fallback && (
              <CopyImage src={mediaCdnReplace(desktop.fallback)} />
            )}
            {link && <CopyLink url={link} />}
          </ControlsWrap>
        )}
      </RelativeDiv>
    )
  }

  return main
}
