import { createContext, useEffect, useState } from 'react'
import {
  getPageViewedInSession,
  setPageViewedInSession,
} from './PageViewProvider.helpers'
import { useRouter } from 'next/router'
import { useDebugger } from '../hooks'

interface IPageViewProvider {
  children: React.ReactNode
}

interface IPageViewContext {
  sessionPageView: number
  setSessionPageView: React.Dispatch<React.SetStateAction<number>>
}

const defaultState = {
  sessionPageView: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSessionPageView: () => {},
} as IPageViewContext
export const PageViewContext = createContext(defaultState)
export const PageViewProvider = ({ children }: IPageViewProvider) => {
  const router = useRouter()
  const { setLog } = useDebugger()
  const [sessionPageView, setSessionPageView] = useState<number>(-1)

  useEffect(() => {
    // initPageViewedInSession()
    if (
      // eslint-disable-next-line
      // @ts-ignore
      window.performance.getEntriesByType('navigation')[0].type === 'reload' ||
      getPageViewedInSession() > 0
    ) {
      setSessionPageView(2)
    } else {
      setSessionPageView(1)
    }
  }, [])

  useEffect(() => {
    const routeChangeStart = () => {
      setSessionPageView(prev => {
        setPageViewedInSession(prev + 1)
        return prev + 1
      })
    }

    router.events.on('routeChangeStart', routeChangeStart)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
    }
  }, [sessionPageView, router.events])

  useEffect(() => {
    setLog(prev => [...prev, `referrer : ${document.referrer}`])
    setLog(prev => [
      ...prev,
      `wasRefreshed : ${
        // eslint-disable-next-line
        // @ts-ignore
        window.performance.getEntriesByType('navigation')[0].type === 'reload'
      }`,
    ])
    setLog(prev => [...prev, `pv is: ${sessionPageView}`])
  }, [sessionPageView, setLog])

  return (
    <PageViewContext.Provider value={{ sessionPageView, setSessionPageView }}>
      {children}
    </PageViewContext.Provider>
  )
}
