import CounterLogo from '../../CounterLogo/CounterLogo'
import { utils } from '../Helpers'
import React from 'react'
import { INextVideos } from '../../../types'
import { ReplayIcon } from '../../../icons'
import {
  BottomWrapper,
  CounterLogoBackGround,
  FlexWrapper,
  NextVideoWrapper,
  Poster,
  ReplayButton,
  Title,
} from './NextVideoTimerScreen.style'
import { isMobile } from '../../../client'
import dynamic from 'next/dynamic'
import { mediaCdnReplace } from '../../../utils'

const PlayerMarqueTitle = dynamic(() => import('./PlayerMarqueTitle'))
type props = {
  isPlayerFullscreen: boolean
  playNextVideoInterval: number
  playerTitle: string
  nextVideo: INextVideos
  replayVideo: string
  playNextVideo: () => void
  replayPreviousVideo: (Video: string | null) => void
}

export const NextVideoTimerScreen = ({
  isPlayerFullscreen,
  playNextVideoInterval,
  playerTitle,
  nextVideo,
  replayVideo,
  playNextVideo,
  replayPreviousVideo,
}: props) => {
  const { kalturaId, poster, videoTitle } = nextVideo
  if (!kalturaId) {
    return null
  }
  return (
    <NextVideoWrapper className={isPlayerFullscreen ? 'full-screen' : ''}>
      <CounterLogoBackGround
        className={isPlayerFullscreen ? 'full-screen' : ''}
      >
        <PlayerMarqueTitle
          className={'playerMarquee ' + 'full-screen-' + isPlayerFullscreen}
          fontSize={utils.isMobile() ? 14 : isPlayerFullscreen ? 36 : 21}
          width={isPlayerFullscreen ? '100%' : '800px'}
          color={'#fff'}
          text={playerTitle}
          speed={30}
          display={'flex'} //'flex'
        />
        <CounterLogo
          logoUrl={
            'https://media3.reshet.tv/image/upload/v1722339876/uploads/2024/904175520.webp'
          }
          CounterLogoWidth={
            utils.isMobile() ? 44 : isPlayerFullscreen ? 134 : 68
          }
          CounterLogoHeight={
            utils.isMobile() ? 44 : isPlayerFullscreen ? 134 : 68
          }
          pathWidth={7}
          pathColor={'white'}
          PathBackgroundColor={'#4c5880'}
          fontColor={'#fff'}
          logoImageWidth={utils.isMobile() ? 14 : isPlayerFullscreen ? 44 : 22}
          logoImageHeight={utils.isMobile() ? 15 : isPlayerFullscreen ? 47 : 24}
          timeToRun={playNextVideoInterval}
          timerBottomText={'...מיד תעברו לכתבה הבאה'}
          timerBottomTextFontColor={'#fff'}
          timerBottomTextFontSize={
            utils.isMobile() ? 12 : isPlayerFullscreen ? 30 : 18
          }
        />

        <BottomWrapper
          theme={{
            isMobileFullScreen: utils.isMobile() && isPlayerFullscreen,
          }}
          className={isPlayerFullscreen ? 'full-screen' : ''}
        >
          <FlexWrapper
            className={isPlayerFullscreen ? 'full-screen' : ''}
            onClick={playNextVideo}
          >
            <Title className={isPlayerFullscreen ? 'full-screen' : ''}>
              {videoTitle || playerTitle}
            </Title>
            <Poster
              className={isPlayerFullscreen ? 'full-screen' : ''}
              src={
                poster
                  ? mediaCdnReplace(poster)
                  : 'https://media3.reshet.tv/image/upload/v1723462808/uploads/2024/904196495.webp'
              }
            />
          </FlexWrapper>
          <ReplayButton
            className={isPlayerFullscreen ? 'full-screen' : ''}
            onClick={() => replayPreviousVideo(replayVideo)}
          >
            <ReplayIcon
              size={isPlayerFullscreen && !isMobile() ? 'big' : 'small'}
            />
          </ReplayButton>
        </BottomWrapper>
      </CounterLogoBackGround>
    </NextVideoWrapper>
  )
}

export default NextVideoTimerScreen
