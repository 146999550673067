import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'
import { AdId, useAdConfigs, usePage, useWindow } from '../../contexts'
import { isGridVodPage, isVodPlaylist } from '../../types'
import { prettyLog } from '../../utils'

export type SizeMapItem = {
  screen: [number, number]
  ad: [number, number]
}
export type SizeMap = SizeMapItem[]

export type IAd = {
  id?: AdId
  collapse?: boolean
  onRender?: (event: googletag.events.SlotRenderEndedEvent) => void
  sizeMap?: SizeMap
  mobileAdId?: AdId
  desktopAdId?: AdId
  sizeOverride?: googletag.GeneralSize | null
}

/**
 *  Component that allocates a Google Publisher Tag slot and tries to fill an ad.
 *  Width and height (or multiple sizes) have to be defined for the API to find a fitting ad.
 *  Targeting and other parameters are optional.
 *  More details at https://developers.google.com/publisher-tag/guides/get-started
 */
const Ad = ({
  id,
  mobileAdId,
  desktopAdId,
  collapse = false,
  sizeMap,
  onRender: renderCallback,
  sizeOverride,
}: IAd) => {
  const adConfig = useAdConfigs({ id, mobileAdId, desktopAdId, renderCallback })
  const slot = useRef<googletag.Slot>()

  useEffect(() => {
    // Make a call only if the configurations have loaded and the slot is not already allocated
    if (adConfig && !slot.current) {
      // Flag [1, 1] ad size
      const isPixelSize = adConfig.size[0] === 1 && adConfig.size[1] === 1

      let adSize: googletag.GeneralSize
      if (sizeOverride) {
        adSize = sizeOverride as googletag.GeneralSize
      } else if (isPixelSize) {
        adSize = ['fluid'] as googletag.GeneralSize
      } else {
        adSize = adConfig.size as googletag.GeneralSize
      }

      window.googletag.cmd.push(() => {
        const newSlot = window.googletag.defineSlot(
          adConfig.name,
          adSize,
          adConfig.id
        )

        // If slot is not properly initialized, return
        if (newSlot) {
          newSlot.addService(window.googletag.pubads())
          newSlot.setCollapseEmptyDiv(collapse)
          if (sizeMap) {
            const sizeMapping = googletag.sizeMapping()
            sizeMap.forEach(size => {
              sizeMapping.addSize(size.screen, size.ad)
            })
            const sizeMapArray = sizeMapping.addSize([0, 0], []).build()

            newSlot.defineSizeMapping(sizeMapArray)
          }
        }

        window.googletag.display(adConfig.id)
        if (newSlot) {
          slot.current = newSlot
        }
      })
    }
  }, [adConfig, slot, collapse, sizeMap, sizeOverride, id])

  useEffect(() => {
    return () => {
      try {
        const slots = googletag.pubads().getSlots()
        const currentSlot = slots.find(slot => {
          const slotName = slot.getSlotElementId()
          return slotName === id
        })
        if (currentSlot) {
          googletag.destroySlots([currentSlot])
        }
      } catch (e) {
        prettyLog('Ad: 101', { e }, 'error')
      }
    }
  }, [id])
  if (!adConfig) {
    return null
  }

  return <div id={adConfig.domId} />
}

const AdWithKey = ({
  id,
  mobileAdId,
  desktopAdId,
  collapse = false,
  sizeMap,
  onRender: renderCallback,
  sizeOverride,
}: IAd) => {
  const { asPath } = useRouter()
  const page = usePage()
  const win = useWindow()

  const adsBlocked = useMemo(() => {
    return page.PageMeta?.ads?.block.dfp
  }, [page.PageMeta?.ads?.block.dfp])

  const key = useMemo(() => {
    const isPlaylistPage = page
      ? isGridVodPage(page) && isVodPlaylist(page.Content.PageGrid[0])
      : false
    return id + (isPlaylistPage ? '' : asPath)
  }, [asPath, id, page])

  if (!win || adsBlocked) {
    return <div className={'ad-placeholder'} style={{ display: 'none' }} />
  }

  return (
    <Ad
      key={key}
      id={id}
      mobileAdId={mobileAdId}
      desktopAdId={desktopAdId}
      collapse={collapse}
      sizeMap={sizeMap}
      onRender={renderCallback}
      sizeOverride={sizeOverride}
    />
  )
}

export default AdWithKey as typeof Ad
