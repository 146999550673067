import styled from 'styled-components'
import { colors, device, fonts, layout } from '../../../styles/GlobalVariables'
import { mediaCdnReplace } from '../../../utils'

export const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  margin-bottom: ${layout.gridBlockGap};
  ${device.small} {
    margin: 0;
    margin-bottom: ${layout.gridBlockGap};
  }
`
export const TimeLineHead = styled.div`
  cursor: pointer;
  position: relative;
  min-height: 15px;
  background: ${props => props.theme.titleColor};
  color: ${colors.white};
  font-family: ${fonts.narkis};
  font-size: 26px;
  font-weight: bold;
  padding: 15px 12px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;

  ${props =>
    props.theme.hasLink &&
    `
        a, :visited{
            color: ${colors.white};
        }

        &:after{
            position: absolute;
            font-family: ${fonts.reshet};
            font-size: 13px;
            content: "\\e91e";
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    `}

  ${props =>
    props.theme.bg_mobile
      ? `background-image: url(${props.theme.bg_mobile});`
      : ''}
    ${device.small} {
    font-size: 34px;
    ${props =>
      props.theme.bg_desktop
        ? `background-image: url(${props.theme.bg_desktop});`
        : ''}
  }
`

export const TimeLineElementsWrap = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  .swiper-wrapper {
    padding: 0 4px;
  }
`

export const TimeLineElement = styled.div`
  font-family: ${fonts.narkis};
  width: unset;
  padding-bottom: 150%;
  position: relative;
  cursor: pointer;

  ${device.small} {
    width: calc((100% / 6.3) + 1.9px);
    padding-bottom: 34.5%;
  }

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 39%,
      rgba(0, 0, 0, 95%) 94%
    ),
    url(${props => mediaCdnReplace(props.theme.img)});

  &:hover {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 29.12%,
        ${props => mediaCdnReplace(props.theme.timeLineColor)} 100%
      ),
      url(${props => mediaCdnReplace(props.theme.img)});
  }
`

export const TimeLineElementInWrap = styled.div`
  ${device.small} {
    position: absolute;
  }

  left: 0;
  top: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.inWrap === 'in-wrap'
      ? `
        position: relative;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        height: 0;
        top: 200px;
    `
      : ''}
`

export const DateElement = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  width: 66px;
  height: 24px;
  line-height: 24px;
  top: 30%;
  border-radius: 4px;
  background: linear-gradient(
    290deg,
    ${props => props.theme.bgColor} 0%,
    ${props => props.theme.bgColor} 94%
  );
  text-align: center;
  margin-bottom: 10px;
`

export const TitleElement = styled.div`
  font-size: 20px;
  line-height: 1.38;
  font-weight: bold;
  text-align: center;
  color: #fff;
  min-height: 80px;
  max-height: 80px;
  overflow: hidden;
  max-width: 62%;
`
