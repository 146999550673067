import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AdId,
  useDebugger,
  usePage,
  usePageLoader,
  usePageView,
} from '../../../contexts'
import { utils } from '../../Player/Helpers'
import Ad from '../Ad'
import {
  Container,
  Content,
  PortalLogo,
  SkipButton,
  Strip,
  VodLogo,
} from './Maavaron.styles'
import { he } from '../../../locales/he'
import { useRootStore } from '../../../store'
import { ottLog, taboolaLog } from '../../../utils'
import { isDesktop, isNativeApp } from '../../../client'
import { useFL } from '../../../hooks/useFL'

export const Maavaron = ({
  theme = 'portal',
}: {
  theme?: 'portal' | 'vod' | 'ott'
}) => {
  const receiveTimeOut = useRef<NodeJS.Timeout | null>(null)
  const { sessionPageView } = usePageView()
  const [setIsShowingMaavaron] = useRootStore(state => [
    state.setIsShowingMaavaron,
    state.isShowingMaavaron,
  ])
  const router = useRouter()
  const { setLoading } = usePageLoader()
  const { SiteMeta } = usePage()
  // const pageViewedInSession = useRef(0)
  const { fontColor, isDarkTheme, isFaceLift } = useFL()
  const [forceEmpty, setForceEmpty] = useState<null | boolean>(null)
  const { setLog } = useDebugger()
  useEffect(() => {
    if (utils.getParameterByName('response') === 'isempty_true') {
      sessionStorage.setItem('isEmpty', 'true')
    }
    setForceEmpty(!!sessionStorage.getItem('isEmpty'))
  }, [])

  const [slotRenderEventReceived, setSlotRenderEventReceived] = useState<{
    received: boolean
    isEmpty: null | boolean
  }>({
    received: false,
    isEmpty: null,
  })

  useEffect(() => {
    receiveTimeOut.current && clearTimeout(receiveTimeOut.current)
    if (slotRenderEventReceived.received) {
      if (slotRenderEventReceived.isEmpty) {
        setLoading(false)
      } else {
        receiveTimeOut.current = setTimeout(() => {
          setLoading(false)
        }, 1500)
      }
    }
  }, [setLoading, slotRenderEventReceived])

  const [showMaavaron, setShowMaavaron] = useState(false)
  const [outOfPage, setOutOfPage] = useState(true)
  const maavronTimeout = useRef<null | NodeJS.Timeout>(null)
  const maavronConfig = useMemo(
    () =>
      SiteMeta?.config?.maavaron || {
        desktop: 5,
        mobile: 10,
      },
    [SiteMeta?.config?.maavaron]
  )

  const { dailyClicksCount } = useRootStore()

  const [MaavaronId, setMaavaronId] = useState<
    | false
    | 'Maavaron_Desktop'
    | 'Maavaron_ros'
    | 'Maavaron_Desktop_T'
    | 'Maavaron_ros_T'
  >(false)

  const clearInterstitialTimeOut = () => {
    if (maavronTimeout.current) {
      clearTimeout(maavronTimeout.current)
    }
  }
  const closeMaavaron = useCallback(() => {
    taboolaLog({ msg: 'show taboola timeout (push)' })
    setOutOfPage(true)

    setIsShowingMaavaron(false)
    const wrappers = document.querySelectorAll('.maavaronWrapper')
    setLog(prev => [...prev, 'מוחק מעברונים' + wrappers.length])
    wrappers.forEach(w => w.remove())
    // taboolaFlush()
  }, [setIsShowingMaavaron, setLog])

  const getDailyClicksCount = (): number => {
    const clicksCount = sessionStorage.getItem('ClicksCount')
    return Number(clicksCount)
  }

  useEffect(() => {
    const routeChangeComplete = () => {
      if (MaavaronId && showMaavaron) {
        const to =
          (utils.isMobile() ? maavronConfig.mobile : maavronConfig.desktop) +
          0.5
        setLog(prev => [...prev, `time out for interstitial is ${to}s`])
        maavronTimeout.current = setTimeout(() => {
          setLog(prev => [...prev, 'interstitial time out close'])
          closeMaavaron()
        }, to * 1000)
      }
    }
    router.events.on('routeChangeComplete', routeChangeComplete)
    // if ((MaavaronId && showMaavaron) || isNativeApp()) {
    //   const to =
    //     (utils.isMobile() ? maavronConfig.mobile : maavronConfig.desktop) + 0.5
    //   setLog(prev => [...prev, `time out for interstitial is ${to}s`])
    //   maavronTimeout.current = setTimeout(() => {
    //     setLog(prev => [...prev, 'interstitial time out close'])
    //     closeMaavaron()
    //   }, to * 1000)
    // }
    return () => {
      router.events.off('routeChangeComplete', routeChangeComplete)
      if (maavronTimeout.current) {
        return clearTimeout(maavronTimeout.current)
      }
    }
  }, [
    maavronTimeout,
    setIsShowingMaavaron,
    MaavaronId,
    showMaavaron,
    closeMaavaron,
    maavronConfig.mobile,
    maavronConfig.desktop,
    setLog,
    router.events,
  ])

  useEffect(() => {
    if (slotRenderEventReceived.received && !slotRenderEventReceived.isEmpty) {
      taboolaLog({ msg: 'reset time out' })
      clearInterstitialTimeOut()
      maavronTimeout.current = setTimeout(
        closeMaavaron,
        ((utils.isMobile() ? maavronConfig.mobile : maavronConfig.desktop) +
          0.5) *
          1000
      )
    }
  }, [
    closeMaavaron,
    maavronConfig.desktop,
    maavronConfig.mobile,
    slotRenderEventReceived.isEmpty,
    slotRenderEventReceived.received,
  ])

  useEffect(() => {
    if (forceEmpty !== null) {
      if (forceEmpty) {
        setMaavaronId(
          isDesktop()
            ? 'Maavaron_Desktop_T'
            : !isNativeApp() && 'Maavaron_ros_T'
        )
      } else {
        if (isDesktop()) {
          setMaavaronId('Maavaron_Desktop')
        } else if (!isNativeApp()) {
          setMaavaronId('Maavaron_ros')
        }
      }
    }
  }, [forceEmpty])

  useEffect(() => {
    setIsShowingMaavaron(true)
    const onRouteChangeStart = () => {
      maavronTimeout.current = setTimeout(
        closeMaavaron,
        ((utils.isMobile() ? maavronConfig.mobile : maavronConfig.desktop) +
          0.5) *
          1000
      )
      setIsShowingMaavaron(true)
      setSlotRenderEventReceived({
        isEmpty: null,
        received: false,
      })
    }
    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [
    router.events,
    setIsShowingMaavaron,
    closeMaavaron,
    maavronConfig.mobile,
    maavronConfig.desktop,
  ])

  // TODO: Remove full screen size setting
  // const [size, setSize] = useState<googletag.SingleSizeArray | null>(null)

  //for ROS maavaron on mobile
  useEffect(() => {
    const messageEvent = (e: MessageEvent) => {
      if (e.data === 'ROS_MAAVRON_CLOSED') {
        setOutOfPage(true)
        setIsShowingMaavaron(false)
        clearInterstitialTimeOut()
        taboolaLog('interstitial closed')
        // taboolaFlush()
      }
    }
    window.addEventListener('message', messageEvent)

    return () => {
      window.removeEventListener('message', messageEvent)
    }
  }, [maavronTimeout, router.events, setIsShowingMaavaron])

  // useEffect(() => {
  //   utils.initPageViewedInSession()
  // }, [])

  // useEffect(() => {
  //   const pv = utils.getPageViewedInSession() + 1
  //   utils.setPageViewedInSession(pv)
  //   pageViewedInSession.current = pv
  //   // Math.max(pv, pageViewedInSession.current + 1)
  // }, [router.asPath])

  useEffect(() => {
    let shuldShow = false
    if (MaavaronId) {
      switch (MaavaronId) {
        case 'Maavaron_Desktop_T':
        case 'Maavaron_Desktop':
          shuldShow = sessionPageView >= 2 && sessionPageView % 2 == 0
          break
        case 'Maavaron_ros_T':
        case 'Maavaron_ros':
          shuldShow = sessionPageView > 1
          if (shuldShow) {
            shuldShow = getDailyClicksCount() % 3 == 0
          }
          break
      }

      if (shuldShow) {
        setOutOfPage(!shuldShow)
      } else {
        setIsShowingMaavaron(false)
      }

      taboolaLog({
        msg: `interstitial request ${shuldShow ? '' : 'not '}send to dfp`,
      })
      // if (!shuldShow) {
      //   taboolaFlush()
      // }
      setShowMaavaron(shuldShow)
    }
  }, [
    MaavaronId,
    router.asPath,
    setIsShowingMaavaron,
    dailyClicksCount,
    sessionPageView,
  ])

  const onRender = useCallback(
    (event: googletag.events.SlotRenderEndedEvent) => {
      // If the ad unit fills, bring ad popup into view and increment the ad display counter
      ottLog({ m: 'Maavaron', isEmpty: event.isEmpty })
      if (!event.isEmpty) {
        setOutOfPage(false)
        incrementMaavaronCounter()
        // setIsShowingMaavaron(true)
      } else {
        setOutOfPage(true)
        setIsShowingMaavaron(false)
        // taboolaFlush()
      }
      setSlotRenderEventReceived({
        received: true,
        isEmpty: event.isEmpty,
      })
    },
    [setIsShowingMaavaron]
  )

  const onSkip = () => {
    taboolaLog({ msg: 'interstitial closed' })
    clearInterstitialTimeOut()
    setOutOfPage(true)
    setIsShowingMaavaron(false)
    // taboolaFlush()
  }

  useEffect(() => {
    if (slotRenderEventReceived.received) {
      if (slotRenderEventReceived.isEmpty) {
        clearInterstitialTimeOut()
      }
      taboolaLog({
        msg: `event SlotRenderEndedEvent isEmpty ${
          slotRenderEventReceived.isEmpty ? 'true' : 'false'
        } received`,
      })
    }
  }, [slotRenderEventReceived.isEmpty, slotRenderEventReceived.received])

  if (!MaavaronId || !showMaavaron) {
    return null
  }

  return (
    <Container
      id={`Container-${MaavaronId}`}
      outOfPage={outOfPage}
      theme={{
        pageType: theme,
        id: MaavaronId,
        show:
          slotRenderEventReceived.isEmpty === false &&
          slotRenderEventReceived.received,
      }}
      isFlDarkTheme={isDarkTheme}
      key={'interstitial'}
    >
      {MaavaronId === 'Maavaron_Desktop' ? (
        <Content>
          <Strip theme={{ type: theme }}>
            {theme !== 'ott' && !isFaceLift && <VodLogo />}
            {theme !== 'ott' && !isFaceLift && <PortalLogo />}
            <SkipButton
              onClick={onSkip}
              children={`${he.skip}`}
              fontColor={fontColor}
              theme={{ type: theme, isFaceLift: isFaceLift }}
            />
          </Strip>
          <Ad
            id={MaavaronId}
            collapse={true}
            onRender={event => onRender(event)}
          />
        </Content>
      ) : (
        <Ad
          id={MaavaronId as AdId}
          collapse={true}
          onRender={event => onRender(event)}
        />
      )}
    </Container>
  )
}

const maavaronCounterKey = 'maavaronCounter'
const incrementMaavaronCounter = () => {
  setMaavaronCounter(getMaavaronCounter() + 1)
}

const getMaavaronCounter = () => {
  const counterString = sessionStorage.getItem(maavaronCounterKey)
  const counter = counterString && parseInt(counterString, 10)

  if (counter) {
    return counter
  }

  setMaavaronCounter(0)
  return 0
}

const setMaavaronCounter = (value: number) => {
  sessionStorage.setItem(maavaronCounterKey, JSON.stringify(value))
}
