import { useEffect, useRef } from 'react'
import { usePage } from '../../contexts'
import { IMainStandard, isHomepage } from '../../types'
import { addAnalyticsToUrl } from '../../utils'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import Newsflashes from '../Newsflashes/Newsflashes'
import {
  Container,
  NewsflashesContainer,
  PostContainer,
  SecondaryTitle,
  StyledComponentTitle,
  TaboolaItemWrapper,
  TextContainer,
  Thumbnail,
  ThumbnailWrap,
  Title,
} from './MainStandard.styles'
import { PlayButton } from '../../icons'
// import PlayButton from '/public/assets/images/svg/play-button.svg'

type Props = {
  mainStandard: IMainStandard
}

export const MainStandard = ({ mainStandard }: Props) => {
  const page = usePage()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  // const textContainerRef = useRef<HTMLDivElement | null>(null)
  // const thumbnailRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (isHomepage(page)) {
      if (wrapperRef.current) {
        wrapperRef.current.dataset.tbRegionItem = ''
      }
      // if (textContainerRef.current) {
      //   textContainerRef.current.dataset.tbRegionItem = ''
      // }
      // if (thumbnailRef.current) {
      //   thumbnailRef.current.dataset.tbRegionItem = ''
      // }
    }
  }, [page])
  if (mainStandard.posts === null) {
    return null
  }

  const post = mainStandard.posts[0]
  const linkTarget = undefined
  const { grid_title, newsFlash } = mainStandard

  const link = addAnalyticsToUrl(
    post?.link,
    page?.PageMeta.id.toString() as string,
    mainStandard.grid_id.toString()
  )

  return (
    <Container>
      <StyledComponentTitle componentTitle={grid_title} />
      <PostContainer>
        <TaboolaItemWrapper ref={wrapperRef}>
          <TextContainer>
            {post.title && (
              <Link href={link} target={linkTarget}>
                <Title>{post.title}</Title>
              </Link>
            )}
            <SecondaryTitle href={link} target={linkTarget}>
              {post.secondaryTitle}
            </SecondaryTitle>
            <Credits credits={post} />
          </TextContainer>
          <ThumbnailWrap>
            <Thumbnail href={post?.link} target={linkTarget}>
              <Image
                image={post.imageObj}
                alt={post.title || post.secondaryTitle}
              />
              <PlayButton show={!!post.video} />
            </Thumbnail>
          </ThumbnailWrap>
        </TaboolaItemWrapper>
        <NewsflashesContainer>
          <Newsflashes
            newsFlashColor={newsFlash.color}
            newsFlash={newsFlash.newsFlashArr}
          />
        </NewsflashesContainer>
      </PostContainer>
    </Container>
  )
}
export default MainStandard
