import { useRouter } from 'next/router'
import React, { createContext, useEffect, useState } from 'react'
import { isNativeApp } from '../../client'
import { prettyLog } from '../../utils'

interface ILoaderContext {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = {
  loading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
} as ILoaderContext

interface ILoaderProps {
  children: React.ReactNode
}

export const LoaderContext = createContext(defaultState)
export const LoaderProvider = ({ children }: ILoaderProps) => {
  const router = useRouter()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isNativeApp()) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('lastRoute', router.asPath || '')
    const onRouteChangeStart = (url: string) => {
      const lastRoute = sessionStorage.getItem('lastRoute') || ''
      prettyLog('LoaderProvider: 36', { lastRoute, url })
      sessionStorage.setItem('lastRoute', url)
      if (!isNativeApp()) {
        // prettyLog('Maavaron: 64', { m: 'setLoading true' })
        setLoading(true)
      }
    }
    // const routeChangeComplete = () => {
    //
    // }

    router.events.on('routeChangeStart', onRouteChangeStart)
    // router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      // router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [router.asPath, router.events])

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  )
}
