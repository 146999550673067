export const ShareIcon = () => {
  return (
    <svg
      width='18'
      height='22'
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.60003 0.461446C8.30357 0.585477 4.9277 3.61132 4.81554 3.85358C4.58633 4.3486 4.92087 4.90758 5.50483 5.00535C5.92864 5.07628 6.07658 4.99107 7.09347 4.09041L8.04007 3.25198L8.04241 8.17434C8.04487 13.3886 8.04145 13.3134 8.28832 13.557C8.44329 13.7098 8.76258 13.8342 9 13.8342C9.23742 13.8342 9.55671 13.7098 9.71168 13.557C9.95855 13.3134 9.95513 13.3886 9.95759 8.17434L9.95993 3.25198L10.9065 4.09041C11.7013 4.79425 11.8896 4.93745 12.0806 4.98275C12.86 5.16748 13.5073 4.47254 13.164 3.81942C13.025 3.55494 9.63324 0.543277 9.36168 0.443225C9.09978 0.346693 8.8607 0.35245 8.60003 0.461446ZM1.56052 7.19337C1.36176 7.27254 1.13223 7.48734 1.0573 7.66428C1.01821 7.75653 1.00056 9.81505 1.00066 14.2774C1.00072 21.5417 0.965998 20.9251 1.39216 21.2289L1.5971 21.375H9H16.4029L16.6078 21.2289C17.034 20.9251 16.9993 21.5422 16.9993 14.2727C16.9994 8.93461 16.9867 7.76176 16.9274 7.63511C16.8318 7.43097 16.5109 7.18514 16.2939 7.14984C16.198 7.13423 15.0737 7.12781 13.7955 7.13561L11.4714 7.14974L11.2756 7.28305C11.016 7.4597 10.8998 7.66866 10.8998 7.95854C10.8998 8.24842 11.016 8.45737 11.2756 8.63402L11.4713 8.76733L13.3022 8.78099L15.1329 8.79469V14.2641V19.7336H9H2.86709V14.2624V8.79112H4.63225C6.57126 8.79112 6.6223 8.78451 6.89268 8.49781C7.27228 8.09537 7.14573 7.49748 6.62523 7.23452C6.41447 7.128 6.40279 7.12753 4.06701 7.12862C2.22383 7.12943 1.68616 7.14337 1.56052 7.19337Z'
        fill='white'
        stroke='white'
        strokeWidth='0.2'
      />
    </svg>
  )
}
export default ShareIcon
