import { IConfigFile, ILiveSources } from '../utils'

export interface ClientConfig {
  configFile: IConfigFile | undefined
  kaltura_application_name: string
  liveSources: ILiveSources | undefined
  login_page: string
  kalturaPlayerId: string
  newsFlashQuerySource: string | undefined
  baseUrl: string
  googleTagManager: string
  googleAnalytics: string
  disableMetrics: string
  geo_plugin_key: string
  geo_ttl: number
  live_ima: 'enabled' | 'disabled'
  live_mid_rolls: 'enabled' | 'disabled'
  dataQuerySource: string
  kaltura_end_point: string
  kaltura_partner_id: string | number
  bigBrotherRegistration: 'on' | 'off'
  adTargetEnv: string
}

export const clientConfig: ClientConfig = {
  newsFlashQuerySource: '',
  baseUrl: '',
  googleTagManager: '',
  googleAnalytics: '',
  kalturaPlayerId: '',
  disableMetrics: '',
  live_ima: 'enabled',
  live_mid_rolls: 'enabled',
  dataQuerySource: '',
  geo_plugin_key: '',
  geo_ttl: 60,
  login_page: '',
  kaltura_end_point: 'https://5031.frp1.ott.kaltura.com/api_v3/service/',
  kaltura_partner_id: 5031,
  kaltura_application_name: 'com.kaltura.reshet.web',
  bigBrotherRegistration: 'on',
  liveSources: {
    mobile: {
      live_with_subs:
        'https://reshet.g-mana.live/media/6f10d1da-0803-48d9-9272-57a811958974/mainManifest.m3u8',
      live_no_subs:
        'https://reshet.g-mana.live/media/cdefce3a-14ec-46cc-a147-1275c4a8b9ed/mainManifest.m3u8',
    },
    desktop: {
      live_with_subs:
        'https://reshet.g-mana.live/media/4607e158-e4d4-4e18-9160-3dc3ea9bc677/mainManifest.m3u8',
      live_no_subs:
        'https://reshet.g-mana.live/media/87f59c77-03f6-4bad-a648-897e095e7360/mainManifest.m3u8',
    },
  },
  adTargetEnv: '',
  configFile: {
    ros_interstitial: 'off',
  },
}
