import {
  PageLoaderContent,
  PageLoaderLogo,
  PageLoaderSpinner,
  PageLoaderWrapper,
} from './PageLoader.style'
import { useDetectAdBlock } from 'adblock-detect-react'
import { useTaboola } from '../Taboola/useTaboola'
import { Logo, Spinner } from './Icons'
import { useEffect, useState } from 'react'
import { useRootStore } from '../../store'
import { usePageLoader } from '../../contexts'

export const PageLoader = () => {
  const { loading } = usePageLoader()
  const { isShowingMaavaron } = useRootStore()
  const [display, setDisplay] = useState<'none' | 'flex'>('flex')

  const blockAds = useDetectAdBlock()
  const blockedTaboola = useTaboola().scriptLoad === 'failed'

  useEffect(() => {
    const tdisplay =
      blockedTaboola || blockAds || !loading || !isShowingMaavaron
        ? 'none'
        : 'flex'

    setDisplay(tdisplay)
  }, [blockAds, blockedTaboola, isShowingMaavaron, loading, setDisplay])

  return (
    <PageLoaderWrapper
      theme={{
        display: display,
      }}
    >
      <PageLoaderContent>
        <PageLoaderLogo>
          <Logo />
        </PageLoaderLogo>
        <PageLoaderSpinner>
          <Spinner />
        </PageLoaderSpinner>
      </PageLoaderContent>
    </PageLoaderWrapper>
  )
}
