import styled, { keyframes } from 'styled-components'
import { device, zIndex } from '../../styles/GlobalVariables'

export const PageLoaderWrapper = styled.div`
  ${device.small} {
    display: none;
  }

  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 100vw;
  width: 100dvw;
  min-height: 120vh;
  min-height: 120dvh;
  background-color: ${props => props.theme.desktop_background || '#fff'};
  z-index: ${zIndex.loader};
  display: ${props => props.theme.display || 'block'};
  align-items: center;
`
export const PageLoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
`
export const PageLoaderLogo = styled.div``

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`
export const PageLoaderSpinner = styled.div`
  display: flex;
  animation: ${spin} 1.5s linear infinite;
`
