import { ISponsorship } from '../../types'
import Link from '../Link/Link'
import { Container, Image, Text } from './Sponsorship.styles'
import { mediaCdnReplace } from '../../utils'

export const Sponsorship = ({ sponsorship }: { sponsorship: ISponsorship }) => {
  // Don't display if the text and icon are missing
  if (!sponsorship.text && !sponsorship.icon) {
    return null
  }

  const textAndImage = (
    <Container>
      <Text>{sponsorship.text}</Text>
      <Image
        loading='lazy'
        src={mediaCdnReplace(sponsorship.icon)}
        alt={sponsorship.text}
      />
    </Container>
  )

  if (sponsorship?.link) {
    return <Link href={sponsorship?.link}>{textAndImage}</Link>
  }

  return textAndImage
}
