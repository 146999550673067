import React, { useState } from 'react'
import { SortToggleContainer, ToggleContainerText } from './TimeLineFL.styles'
import Image from 'next/image'

type SortByDropDownProps = {
  sortElementsByDate: (value: 'asc' | 'desc') => void
}

export const SortByFL: React.FC<SortByDropDownProps> = props => {
  const [data, setData] = useState<{ text: string; sort: 'asc' | 'desc' }>({
    text: 'מהחדש לישן',
    sort: 'asc',
  })
  const handleToggle = () => {
    if (data.sort === 'asc') {
      setData({ sort: 'desc', text: 'מהישן לחדש' })
    } else {
      setData({ sort: 'asc', text: 'מהחדש לישן' })
    }
    props.sortElementsByDate(data.sort)
  }
  return (
    <SortToggleContainer onClick={handleToggle}>
      <ToggleContainerText>{data.text}</ToggleContainerText>
      <Image
        width={14}
        height={14}
        src={
          'https://media3.reshet.tv/image/upload/v1713686574/uploads/2024/904028661.png'
        }
      />
    </SortToggleContainer>
  )
}
