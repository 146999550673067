export const Search = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9391 19.8718C5.82712 19.8718 1.68313 15.8 1.68313 10.769C1.68313 5.73805 5.82712 1.65805 10.9391 1.65805C16.051 1.65805 20.1958 5.73805 20.1958 10.769C20.1958 15.8 16.051 19.8718 10.9391 19.8718ZM25.7531 24.5859L19.041 17.9781C20.798 16.0682 21.8782 13.5486 21.8782 10.769C21.8782 4.81964 16.9808 0 10.9391 0C4.89738 0 0 4.81964 0 10.769C0 16.7103 4.89738 21.5299 10.9391 21.5299C13.5495 21.5299 15.9437 20.6278 17.8244 19.1242L24.5633 25.7562C24.8925 26.0813 25.4248 26.0813 25.7531 25.7562C26.0823 25.4392 26.0823 24.911 25.7531 24.5859Z'
        fill='white'
      />
    </svg>
  )
}
