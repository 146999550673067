import { useMemo } from 'react'
import 'swiper/css'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { IProgram, IVod } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { VodPost } from '../Post/VodPost'
import {
  CarouselContainer,
  Container,
  DesktopContainer,
  MobileContainer,
  PostsContainer,
  ProgramContainer,
  ProgramsContainer,
  SlideContainer,
} from './Vod.styles'
import { mediaCdnReplace } from '../../utils'

type ProgramProps = {
  program: IProgram
}

const Program = ({ program }: ProgramProps) => {
  const { link, img, title } = program
  const src = useMemo(() => {
    return mediaCdnReplace(img.replace(/png|jpg|jpeg/g, 'webp'))
  }, [img])

  return (
    <ProgramContainer href={link}>
      <img loading='lazy' src={src} alt={title || ''} />
    </ProgramContainer>
  )
}

const carouselOffset = 0.25

type VodProps = {
  vod: IVod
}

export const Vod = ({ vod }: VodProps) => {
  const swiperProps: SwiperProps = {
    slidesPerView: 2 + carouselOffset,
    dir: 'rtl',
    spaceBetween: 11,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    centerInsufficientSlides: true,
    resistanceRatio: 0.4,
    resistance: true,
    breakpoints: {
      700: {
        slidesPerView: 3 + carouselOffset,
        spaceBetween: 11,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        centerInsufficientSlides: true,
      },
      1000: {
        slidesPerView: 4,
        spaceBetween: 11,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        centerInsufficientSlides: true,
      },
    },
  }

  const programSettings: SwiperProps = {
    slidesPerView: 2 + carouselOffset,
    dir: 'rtl',
    spaceBetween: 11,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    resistanceRatio: 0.4,
    resistance: true,
    breakpoints: {
      600: {
        slidesPerView: 3 + carouselOffset,
        spaceBetween: 11,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        centerInsufficientSlides: true,
      },
    },
  }

  return (
    <Container backgroundUrl={vod.background}>
      <ComponentTitle componentTitle={vod.grid_title} />
      <MobileContainer>
        <CarouselContainer>
          <Swiper {...swiperProps}>
            {vod.posts.map((post, index) => (
              <SwiperSlide key={index}>
                <SlideContainer>
                  <VodPost post={post} />
                </SlideContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainer>
        <CarouselContainer>
          <Swiper {...programSettings}>
            {vod.programs.map((program, index) => (
              <SwiperSlide key={index}>
                <Program program={program} />
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainer>
      </MobileContainer>
      <DesktopContainer>
        <PostsContainer>
          {vod.posts.map((post, index) => (
            <SlideContainer key={index}>
              <VodPost post={post} />
            </SlideContainer>
          ))}
        </PostsContainer>
        <ProgramsContainer>
          {vod.programs.map((program, index) => (
            <Program program={program} key={index} />
          ))}
        </ProgramsContainer>
      </DesktopContainer>
    </Container>
  )
}
