interface Env {
  BASE_URL?: string
  NEWSFLASH_QUERY_SOURCE?: string
  DATA_QUERY_SOURCE?: string
  DATA_QUERY_REPLACE_URLS?: '1' | '0'
  DATA_QUERY_USERNAME?: string
  DATA_QUERY_PASSWORD?: string
  PREBUILD_PAGES?: '1' | '0'
  REVALIDATE_IN_SECONDS?: string
  AD_TARGET_ENV?: string
  GOOGLE_TAG_MANAGER: string
  GOOGLE_ANALYTICS: string
  QDATA_REQUEST_TIMEOUT_IN_SECONDS?: string
  KALTURA_PLAYER_ID?: string
  DISABLE_METRICS?: '1' | '0'
  LIVE_IMA?: 'enabled' | 'disabled'
  LIVE_MID_ROLLS?: 'enabled' | 'disabled'
  GEO_PLUGIN_KEY?: string
  GEO_TTL?: number
  ENABLE_PUSH_NOTIFICATION?: 'enabled' | 'disabled'
  ENABLE_FULL_SCREEN?: 'enabled' | 'disabled'
  KALTURA_CLIENT_TAG: string
  LOGIN_PAGE?: string
  BIG_BROTHER_REGISTRATION?: 'on' | 'off'
  SHOULD_TABOOLA_WAIT_FOR_INTERSTITIAL?: 'on' | 'off'
  TABOOLA_PUBLISHER_ID: number
}

const env = (typeof process !== 'undefined' ? process.env : {}) as Env

export const config = {
  baseUrl: env.BASE_URL ?? '',
  newsFlashQuerySource: env.NEWSFLASH_QUERY_SOURCE ?? '',
  dataQuerySource: env.DATA_QUERY_SOURCE ?? '',
  dataQueryUsername: env.DATA_QUERY_USERNAME,
  dataQueryPassword: env.DATA_QUERY_PASSWORD,
  replaceUrlsFromDataQuery: env.DATA_QUERY_REPLACE_URLS === '1',
  prebuildPages: env.PREBUILD_PAGES === '1',
  revalidateInSeconds: env.REVALIDATE_IN_SECONDS
    ? +env.REVALIDATE_IN_SECONDS
    : 1,
  adTargetEnv: env.AD_TARGET_ENV ?? null,
  googleTagManager: env.GOOGLE_TAG_MANAGER,
  qdataRequestTimeoutInSeconds:
    +(env.QDATA_REQUEST_TIMEOUT_IN_SECONDS || '') || 15,
  googleAnalytics: env.GOOGLE_ANALYTICS || '',
  kalturaPlayerId: env.KALTURA_PLAYER_ID || '',
  disableMetrics: env.DISABLE_METRICS ?? '0',
  live_ima: env.LIVE_IMA || 'enabled',
  live_mid_rolls: env.LIVE_MID_ROLLS || 'enabled',
  geo_plugin_key: env.GEO_PLUGIN_KEY,
  geo_ttl: env.GEO_TTL || 4,
  enable_push_notification: env.ENABLE_PUSH_NOTIFICATION || 'disabled',
  enable_full_screen: env.ENABLE_FULL_SCREEN || 'disabled',
  kalturaClientTag: env.KALTURA_CLIENT_TAG,
  login_page: env.LOGIN_PAGE || '',
  liveSources: {
    mobile: {
      live_with_subs:
        'https://reshet.g-mana.live/media/6f10d1da-0803-48d9-9272-57a811958974/mainManifest.m3u8',
      live_no_subs:
        'https://reshet.g-mana.live/media/cdefce3a-14ec-46cc-a147-1275c4a8b9ed/mainManifest.m3u8',
    },
    desktop: {
      live_with_subs:
        'https://reshet.g-mana.live/media/4607e158-e4d4-4e18-9160-3dc3ea9bc677/mainManifest.m3u8',
      live_no_subs:
        'https://reshet.g-mana.live/media/87f59c77-03f6-4bad-a648-897e095e7360/mainManifest.m3u8',
    },
  },
  bigBrotherRegistration: env.BIG_BROTHER_REGISTRATION || 'on',
  shouldTaboolaWaitForInterstitial:
    env.SHOULD_TABOOLA_WAIT_FOR_INTERSTITIAL || 'off',
  kaltura_partner_id: '5031',
  kaltura_end_point: 'https://5031.frp1.ott.kaltura.com/api_v3/service/',
  kaltura_application_name: 'com.kaltura.reshet.web',
  uiconf_id: '50272542' as string | number,
  searchPageId: null as null | number,
  hpId: null as null | number,
  allProgramsID: null as null | number,
  activePage: '' as string | number,
  taboolaPublisherId: env.TABOOLA_PUBLISHER_ID || '1043830',
  configFile: {
    ros_interstitial: 'off',
  },
}
