import { isNativeApp } from './isNativeApp'

declare global {
  let webToAndroid: AndroidWebToNative | null
  interface Window {
    webkit?: { messageHandlers: { webToNative: IosWebToNative } }
  }
}
export interface AndroidWebToNative {
  postMessage(req: string): void
}

export interface IosWebToNative {
  postMessage(req: unknown): void
}

export const webToNative: WebToNative = {
  postMessage: req => {
    if (typeof webToAndroid !== 'undefined' && webToAndroid) {
      webToAndroid.postMessage(JSON.stringify(req))
      return
    }
    const x = window?.webkit?.messageHandlers?.webToNative
    if (x) {
      x.postMessage(req)
      return
    }
    if (!isNativeApp()) {
      return
    }
    if (typeof postMessage !== 'undefined' && postMessage) {
      postMessage(JSON.stringify(req))
      return
    }
    const l = window?.ReactNativeWebView
    if (l) {
      l.postMessage(JSON.stringify(req))
      return
    }
    console.warn(
      'Cannot post a message to native app, no native app bridge available',
      req
    )
  },
}
export interface WebToNative {
  postMessage(req: { action: string; args: unknown }): void
}
