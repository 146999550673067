import styled from 'styled-components'
import {
  colors,
  device,
  headerGlobalStyle,
  headerZIndex,
  themeClassName,
  WarOffset,
} from '../../styles/GlobalVariables'
import Link from '../Link/Link'

interface GlobalWidth {
  medium: string
  large: string
  live: string
}

export const HeaderContainer = styled.div<{
  displaySubmenu: boolean
  maxWidth: GlobalWidth
}>`
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: ${props => (props.displaySubmenu ? '48px 29px' : '48px')};
  justify-content: space-between;
  align-items: center;
  min-height: ${headerGlobalStyle.minHeight};
  padding: 0 20px;
  position: relative;

  ${device.medium} {
    margin: 0 auto;
    min-height: ${headerGlobalStyle.mediumMinHeight};
    width: 100%;
    min-width: ${props => props.maxWidth.medium};
    max-width: ${props => props.maxWidth.medium};
    padding: 0;
    display: grid;
    grid-template-columns: ${headerGlobalStyle.logoWidth} 1fr ${headerGlobalStyle.buttonsWidth};
    grid-template-rows: ${props =>
      props.displaySubmenu ? '64px 29px' : '64px'};
  }

  ${device.large} {
    min-width: ${props => props.maxWidth.large};
    max-width: ${props => props.maxWidth.large};
    grid-template-columns: 196px 1fr ${headerGlobalStyle.buttonsWidth};
  }

  ${props => WarOffset(props.theme.haseWarComp)}
`

export const ButtonsWrapper = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  min-width: 200px;

  ${device.large} {
    width: ${headerGlobalStyle.buttonsWidth};
    position: relative;
    gap: 11px;
  }

  svg {
    display: flex;
    align-items: center;
    font-size: 21px;
  }
`

export const MenuSearch = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${colors.darkBlue};

  cursor: pointer;

  svg {
    font-size: 16px;
  }
`

export const MenuBack = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${colors.darkBlue};

  cursor: pointer;

  svg {
    @supports (-webkit-touch-callout: none) {
      position: relative;
    }
    font-size: 16px;
    margin-left: 12px;
    bottom: 1px;
  }
`

export const VodButtonLink = styled(Link)`
  width: 51px;
  height: 23px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  //background: transparent
  //  url('https://media3.reshet.tv/image/upload/v1686563059/assets/2023/vod_button_mobile.webp');
  /* background-size: stretch */

  ${device.medium} {
    width: 72px;
    height: 30px;
    //background: transparent
    //  url('https://media3.reshet.tv/image/upload/v1686563059/assets/2023/vod_button.webp');
  }
`
export const VodButton = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  display: inline-flex;
  background: rgb(253, 52, 147);
  background: linear-gradient(
    135deg,
    rgba(255, 105, 176, 1) 30%,
    rgba(253, 52, 147, 1) 36%
  );
  color: white;
  height: 100%;
  width: 100%;
  justify-content: center;
  border-radius: 2px;
  line-height: 23px;

  ${device.medium} {
    line-height: 30px;
    align-items: center;
    font-size: 1em;
  }
`

export const LiveIcon = styled(Link)`
  background: ${colors.white};
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  min-height: 23px;
  max-height: 23px;
  padding: 0 6px;
  flex-shrink: 0;
  display: flex;

  width: 51px;
  max-width: 51px;

  span,
  div {
    color: ${colors.darkBlue};
    letter-spacing: 0.045em;
    margin-bottom: -1px;
    font-weight: 600;
    font-size: 15px;
  }

  :after {
    content: '';
    height: 4px;
    width: 4px;
    background-color: ${colors.liveRed};
    border-radius: 100%;
    animation: blink 1.5s infinite;
    margin-right: 3px;

    @keyframes blink {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    ${device.medium} {
      height: 6px;
      width: 6px;
    }
  }

  ${device.medium} {
    padding: 0 12px;
    min-height: 30px;
    max-height: 30px;
    width: 72px;
    max-width: 72px;

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
`

export const BBLiveIcon = styled(Link)`
  min-width: 24px;
  max-width: 51px;

  img {
    &.mobile {
      display: flex;
    }

    &.desktop {
      display: none;
    }
  }

  ${device.small} {
    max-width: 72px;
    min-width: 30px;

    img {
      &.mobile {
        display: none;
      }

      &.desktop {
        display: flex;
      }
    }
  }
`
export const SearchIcon = styled.span`
  cursor: pointer;
  display: none;
  ${device.medium} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & svg {
    position: relative;
    width: 20px;
    height: 20px;
  }
`
export const LogoLink = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-right: 8px;

  ${device.medium} {
    margin: unset;
    position: unset;
    justify-content: flex-start;
    width: ${props =>
      props.theme.inFooter ? 'initial' : headerGlobalStyle.logoWidth};
    //margin-left: 60px;
  }
`

export const LogoImage = styled.img`
  max-height: 29px;
  max-width: 108px;

  ${device.medium} {
    max-height: 48px;
    max-width: unset;
  }

  ${props => {
    switch (props.theme.visible) {
      case 'mobile':
        return `
                    display: block;
                    ${device.small} {
                        display: none;
                    }
                `
      case 'desktop':
        return `
                    display: none;
                    ${device.small} {
                        display: block;
                    }
                `
    }
  }}
`

export const HamburgerIcon = styled.div`
  /* flex: 1; */
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-size: 19px;

  a {
    display: flex;
    cursor: pointer;
  }

  ${device.medium} {
    display: none;
  }
`

export const ShareWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${device.medium} {
    display: none;
  }
`

export const MobileMenu = styled.div<{ opened: boolean }>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  flex-direction: column;
  padding: 0 20px;
  background: ${colors.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 48px);
  overflow: auto;

  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${headerZIndex};

  ${device.medium} {
    display: none;
  }
`

export const DesktopMenu = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-start;
  }
`

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 23px;
`

export const Children = styled.div<{ background: string }>`
  display: none;
  flex-direction: column;
  padding: 0 20px;

  position: absolute;
  top: 23px;
  padding-top: 4.5px;
  z-index: 4001;
  background: ${props => props.background};
  padding: 16px 13px 9px 13px;

  ${device.medium} {
    width: 160px;
  }

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    background: ${colors.white};
  }
`

export const Item = styled.div<{
  iconSize?: number
  active?: boolean
  subItems?: boolean
}>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.menuOptionGray};
  max-height: 64px;

  svg {
    font-size: ${props => props.iconSize}px;
  }

  > a {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${colors.darkBlue};
    padding: 20px 0;
    ${props =>
      props.subItems && {
        pointerEvents: 'none',
      }}
  }

  :last-child {
    border-bottom: none;
  }

  ${device.medium} {
    flex: unset;
    border-bottom: none;
    padding: 0;
    gap: 5px;

    > svg {
      align-self: center;
    }

    > a {
      ${props =>
        props.active && {
          borderBottom: `2px solid ${colors.white}`,
        }}
      font-size: 19px;
      font-weight: 700;
      line-height: 23px;
      color: ${colors.white};
      padding: 0;
    }
  }
`

export const Group = styled.div<{ subItems: boolean }>`
  display: none;
  cursor: pointer;

  ${device.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    :hover {
      cursor: auto;

      ${Item} {
        a {
          ${props =>
            props.subItems && {
              pointerEvents: 'none',
            }}
        }
      }

      ${Children} {
        display: flex;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const MenuChild = styled(Link)`
  flex: 1;
  padding: 12px 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.white};
  border-bottom: 1px solid ${colors.desktopMenuOptionGray};

  > span,
  div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    color: ${colors.tvGuideDark};
    border-bottom-color: ${colors.desktopMenuOptionGrayLighter};
  }

  :last-child {
    border-bottom: unset;
    padding: 12px 0 9px;
  }
`

export const Action = styled.span`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 20px;
  border-bottom: 1px solid ${colors.menuOptionGray};
  max-height: 74px;

  ${device.medium} {
    display: none;
  }
`

export const MenuArrow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 13px;
  }
`

export const Container = styled.div<{
  displaySubmenu: boolean
  isFaceLift?: boolean
}>`
  display: flex;
  flex-direction: column;
  box-shadow: ${props =>
    props.isFaceLift ? '' : '0 2px 4px rgba(0, 0, 0, 0.25)'};
  position: fixed;
  width: 100%;
  z-index: ${headerZIndex};
  top: 0;

  transition: all 0.3s ease-in;

  ${device.medium} {
    ${props => props.displaySubmenu && 'box-shadow: 0 8px 8px -4px lightgrey;'}
  }

  ${themeClassName.portal} & {
    background: ${props =>
      props.isFaceLift ? colors.midnightBlue : colors.headerBlue};
  }

  ${themeClassName.vod} & {
    background: ${colors.darkHeaderBg};
    box-shadow: unset;
  }

  ${themeClassName.vodMain} & {
    box-shadow: unset;

    &.is-scrolled {
      background-color: ${colors.darkHeaderBg};
      background-image: none;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    }
  }
`

export const LogoWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: clip;
  ${themeClassName.vodMain} & {
    visibility: hidden;
  }
  @media (min-width: 1700px) {
    display: block;
  }
`

export const TransparentLogo = styled.img`
  margin-top: -10px;
  height: 101px;
  width: auto;
  transform: rotate(14.68deg);
`
