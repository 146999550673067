import { useEffect, useMemo, useState } from 'react'
import { useAdminCookie } from '../../contexts'
import { IImage } from '../../types'
import {
  ControlsWrap,
  DesktopImage,
  MobileImage,
  PlayContainer,
  PlayTextContainerDesktop,
  PlayTextContainerMobile,
  ProfileTitle,
  RelativeDiv,
  StyledPlayButtonDesktop,
  StyledPlayButtonMobile,
} from './ImageFl.styles'
import { getPlayButtonConfig } from '../Post/HelpersFl'
import { CopyImage, CopyLink } from '../Image/Copy'
import { useFL } from '../../hooks/useFL'
import { mediaCdnReplace } from '../../utils'

interface PlayButtonConfig {
  media?: {
    playTop?: string
    playLeft?: string
    playBottom?: string
    playRight?: string
    playWidth?: string
    playHeight?: string
    transformFirst?: string
    transformSecond?: string
  }
  desktop?: {
    playTop?: string
    playLeft?: string
    playBottom?: string
    playRight?: string
    playWidth?: string
    playHeight?: string
    transformFirst?: string
    transformSecond?: string
  }
}

type Props = {
  image: IImage
  alt?: string | undefined
  refDesktop?: React.Ref<HTMLImageElement>
  link?: URL | string
  disableControls?: boolean
  forAdmin?: boolean
  eager?: boolean
  playButton?: boolean
  isBigPost?: boolean
  playButtonName?: string
  reelsTitle?: string
  contestantsTitle?: string
  reelsType?: 'media' | 'desktop'
}
const defaultImageSrc =
    'https://media3.reshet.tv/image/upload/f_auto,q_auto:good/v1633341900/assets/2021/default_image_tv.webp',
  defaultImage: IImage = {
    d: defaultImageSrc,
    alt_d: 'רשת 13',
    m: defaultImageSrc,
    alt_m: 'רשת 13',
  }

export const ImageFl = ({
  image,
  alt,
  refDesktop,
  link,
  disableControls,
  forAdmin = true,
  eager = false,
  playButton = false,
  isBigPost = false,
  playButtonName,
  reelsTitle,
  contestantsTitle,
}: Props) => {
  const admin_cookie = useAdminCookie()
  const [isMobile, setIsMobile] = useState(false)
  const { backgroundColor, fontColor } = useFL()

  image = image || defaultImage

  const desktop = useMemo(() => {
    return {
      src: mediaCdnReplace(image.d || '').replace(/png|jpg|jpeg/g, 'webp'),
      alt: image.alt_d,
      fallback: image.d || '',
    }
  }, [image.alt_d, image.d])

  const mobile = useMemo(() => {
    return {
      src: mediaCdnReplace(image.m || '').replace(/png|jpg|jpeg/g, 'webp'),
      alt: image.alt_m,
      fallback: image.m || '',
    }
  }, [image.alt_m, image.m])

  const [playButtonConfig, setPlayButtonConfig] =
    useState<PlayButtonConfig | null>(null)

  useEffect(() => {
    if (image) {
      const railConfig = getPlayButtonConfig({
        bigPost: isBigPost,
        playButtonName: playButtonName,
      })
      if (railConfig) {
        setPlayButtonConfig(railConfig)
      } else {
        setPlayButtonConfig(null)
      }
    }
  }, [image, isBigPost, playButtonName])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!image) {
    return null
  }

  if (playButton && !playButtonConfig) {
    return null
  }

  const renderDesktopPlayButton = () => {
    if (playButtonName === 'reels') {
      return (
        <PlayContainer>
          <StyledPlayButtonDesktop
            {...playButtonConfig?.desktop}
            playPosition='block'
          />
          <PlayTextContainerDesktop>{reelsTitle}</PlayTextContainerDesktop>
        </PlayContainer>
      )
    } else {
      return (
        <StyledPlayButtonDesktop
          {...playButtonConfig?.desktop}
          playPosition='absolute'
        />
      )
    }
  }

  const renderMobilePlayButton = () => {
    if (playButtonName === 'reels') {
      return (
        <PlayContainer>
          <PlayTextContainerMobile>{reelsTitle}</PlayTextContainerMobile>
        </PlayContainer>
      )
    } else {
      return (
        <StyledPlayButtonMobile
          {...playButtonConfig?.media}
          playPosition='absolute'
        />
      )
    }
  }

  const main = (
    <>
      {desktop.src && (
        <div style={{ position: 'relative' }}>
          <DesktopImage
            loading={eager ? 'eager' : 'lazy'}
            src={desktop.src}
            alt={alt || desktop.alt}
            ref={refDesktop}
          />
          {playButton && !isMobile && renderDesktopPlayButton()}
          {contestantsTitle && !isMobile && backgroundColor && (
            <ProfileTitle fontColor={fontColor}>
              {contestantsTitle}
            </ProfileTitle>
          )}
        </div>
      )}

      {mobile.src && (
        <div style={{ position: 'relative' }}>
          <MobileImage
            loading={eager ? 'eager' : 'lazy'}
            src={mobile.src}
            alt={alt || mobile.alt}
          />
          {playButton && isMobile && renderMobilePlayButton()}
          {contestantsTitle && isMobile && backgroundColor && (
            <ProfileTitle fontColor={fontColor}>
              {contestantsTitle}
            </ProfileTitle>
          )}
        </div>
      )}
    </>
  )

  if (admin_cookie && forAdmin) {
    return (
      <RelativeDiv theme={{ isAdmin: admin_cookie && !disableControls }}>
        {main}
        {!disableControls && admin_cookie && (
          <ControlsWrap>
            {desktop.fallback && <CopyImage src={desktop.fallback} />}
            {link && <CopyLink url={link} />}
          </ControlsWrap>
        )}
      </RelativeDiv>
    )
  }
  return main
}
