import { useRouter } from 'next/router'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { getAdDeviceType, isDesktop } from '../../client'
import { useHeader, useWindow } from '../../contexts'
import { IArticlePage, IArticleVideo } from '../../types'
import { ArticleMeta, ArticleTitle } from '../Article'
import ArticleTags from '../Article/ArticleTags/ArticleTags'
import { ArticleVideo } from '../ArticleVideo/ArticleVideo'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { parseHTML } from '../ContentParser/ContentParser'
import { cleanHtmlString } from '../ContentParser/utils'
import { ImageClickable } from '../ImageClickable/ImageClickable'
import { ImageWithCredits } from '../ImageWithCredits/ImageWithCredits'
import { utils } from '../Player/Helpers'
import { ShareButtons } from '../ShareButtons/ShareButtons'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'

import {
  Container,
  Content,
  FullWidth,
  HorizontalContainer,
  ShareButtonsWrapper,
  Sidebar,
  TaboolaLR,
  TaboolaWrapper,
  TextContainer,
} from './Article.styles'
import { mediaCdnReplace } from '../../utils'

type Props = {
  page: IArticlePage
}

export const Article = ({ page }: Props) => {
  const win = useWindow()
  const router = useRouter()
  const articleRef = useRef<HTMLDivElement>(null)
  const { headerHeight } = useHeader()
  const [device, setDevice] = useState<'desktop' | 'mobile' | 'app'>()
  const [stickyShare, setStickyShare] = useState<'top' | 'bottom' | false>(
    false
  )
  const [taboolaPageType, setTaboolaPageType] = useState<
    null | false | PocTaboolaPageType.article | PocTaboolaPageType.article_vod
  >(null)
  const [NextItemPagerComp, setNextItemPagerComp] =
    useState<JSX.Element | null>(null)

  useEffect(() => {
    const loadComponent = async () => {
      const { NextItemPager } = await import('../NextItemPager/NextItemPager')
      setNextItemPagerComp(<NextItemPager objectToSlide={articleRef} />)
    }
    if (utils.isMobile() && localStorage.getItem('itemLinks') != '') {
      loadComponent().then(() => null)
    }
  }, [])

  const article = page.Content.Item
  const { video, title, secondaryTitle, sponsorship, image, tags } = article

  const shareContainerRef = useRef<HTMLDivElement>(null)
  const shareButtonsRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const postContent = cleanHtmlString(article?.postContent || '')

  const { PageMeta, Header, SEO } = page
  const disableTaboola = PageMeta.ads.block.taboola

  const parsedContent = useMemo(
    () =>
      (win &&
        parseHTML({
          html: mediaCdnReplace(postContent),
          adMidArticle: !disableTaboola,
          isArticle: true,
        })) ||
      [],
    [disableTaboola, postContent, win]
  )
  // See https://zemingo.atlassian.net/browse/RES-135

  useEffect(() => {
    if (taboolaPageType !== null) {
      return
    }
    setTaboolaPageType(getPageType({ contentRef, video }))
  }, [taboolaPageType, video, contentRef])

  // TODO: Enable for article ads
  useEffect(() => {
    setDevice(getAdDeviceType())
  }, [device, page.PageMeta.ads.block.taboola, postContent])

  const TaboolaLeftRail = useMemo(() => {
    if (
      !win ||
      win.innerWidth < 768 ||
      disableTaboola ||
      !contentRef.current ||
      !taboolaPageType
    ) {
      return null
    }

    // By default taboola items has 6 items for article
    let suffixId = PocTaboolaSuffixId.articleLeftRail
    const contentHeight = contentRef.current.clientHeight

    if (contentHeight) {
      if (contentHeight < 850) {
        // 2 items
        suffixId = PocTaboolaSuffixId.videoItem
      } else if (contentHeight < 1150) {
        // 3 items
        suffixId = PocTaboolaSuffixId.articleLeftRail3
      } else if (contentHeight < 1450) {
        // 4 items
        suffixId = PocTaboolaSuffixId.articleLeftRail4
      } else if (contentHeight < 1750) {
        // 5 items
        suffixId = PocTaboolaSuffixId.articleLeftRail5
      }
    }

    return (
      <TaboolaLR key={router.asPath + Date.now()}>
        <Taboola pageType={taboolaPageType} suffixId={suffixId} />
      </TaboolaLR>
    )
  }, [disableTaboola, taboolaPageType, win, router.asPath])

  const articleBreadcrumbs = Header.breadCrumbs.slice(
    0,
    Header.breadCrumbs.length - 1
  )
  const shareSize = 38
  const headerShareGap = 20

  useEffect(() => {
    const onScroll = () => {
      if (
        !win ||
        !isDesktop() ||
        !shareContainerRef.current ||
        !shareButtonsRef.current ||
        !headerHeight
      ) {
        return
      }
      const container = shareContainerRef.current
      const buttons = shareButtonsRef.current
      const containerTop = container.offsetTop
      const containerBottom = containerTop + container.offsetHeight
      const buttonsBottom =
        win.scrollY + headerHeight + headerShareGap + buttons.offsetHeight

      const isHeaderOverShare =
        win.scrollY >= containerTop - headerHeight - headerShareGap
      const isButtonsOverContainer = buttonsBottom > containerBottom

      switch (true) {
        case isHeaderOverShare && !isButtonsOverContainer:
          setStickyShare('top')
          break

        case isHeaderOverShare && isButtonsOverContainer:
          setStickyShare('bottom')
          break

        default:
          setStickyShare(false)
          break
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [headerHeight, shareContainerRef, shareButtonsRef, win])

  useEffect(() => {
    if (window.Playbuzz) {
      try {
        window.Playbuzz.render()
      } catch (err) {
        console.error(err)
      }
    } else {
      setTimeout(() => {
        if (window.Playbuzz) {
          try {
            window.Playbuzz.render()
          } catch (err) {
            console.error('Error processing Playbuzz embed:', err)
          }
        }
      }, 1000)
    }
  }, [win])

  if (!article) {
    return null
  }

  return (
    <>
      {NextItemPagerComp}
      <Container ref={articleRef}>
        <HorizontalContainer>
          <Content ref={contentRef}>
            <Breadcrumbs breadcrumbs={articleBreadcrumbs} />
            <ImageClickable headerItem={article.header_items} />
            <ArticleTitle title={title} subtitle={secondaryTitle} />
            {/* TODO: Replace commentNumber placeholder value */}
            <ArticleMeta
              credits={article}
              sponsorship={sponsorship}
              commentNumber={0}
              showComments={false}
            />
            <FullWidth>
              {video && video.kalturaId ? (
                <ArticleVideo
                  pageMeta={PageMeta}
                  video={video}
                  hideDetails={false}
                />
              ) : (
                <ImageWithCredits
                  image={image}
                  showCredits={true}
                  isLazy={false}
                />
              )}
            </FullWidth>
            {article &&
              (win && parsedContent.length > 0 ? (
                <TextContainer>{parsedContent}</TextContainer>
              ) : (
                postContent.length > 0 && (
                  <TextContainer
                    dangerouslySetInnerHTML={{
                      __html: mediaCdnReplace(postContent),
                    }}
                  />
                )
              ))}
            <ArticleTags tags={tags} />
          </Content>
          <Sidebar stickyShare={stickyShare} headerHeight={headerHeight}>
            <ShareButtonsWrapper
              theme={{ size: shareSize }}
              ref={shareContainerRef}
            >
              <ShareButtons
                SEO={SEO}
                size={shareSize + 'px'}
                containerRef={shareButtonsRef}
              />
            </ShareButtonsWrapper>
            {TaboolaLeftRail}
          </Sidebar>
        </HorizontalContainer>
        {taboolaPageType && (
          <TaboolaWrapper key={taboolaPageType + PocTaboolaSuffixId.pageFooter}>
            <Taboola
              pageType={taboolaPageType}
              suffixId={PocTaboolaSuffixId.pageFooter}
            />
          </TaboolaWrapper>
        )}
      </Container>
    </>
  )
}

type GetPageTypeProps = {
  contentRef: RefObject<HTMLDivElement>
  video: IArticleVideo
}
// TODO: find a better name for this function and extract it to a separate file.
const getPageType = ({ contentRef, video }: GetPageTypeProps) => {
  if (!contentRef.current) {
    return false
  }

  let pageType = PocTaboolaPageType.article
  const contentHeight = contentRef.current.clientHeight
  if (contentHeight && video) {
    if (contentHeight < 850) {
      pageType = PocTaboolaPageType.article_vod
    } else if (contentHeight < 1150) {
      // 3 items
      pageType = PocTaboolaPageType.article_vod
    } else if (contentHeight < 1450) {
      // 4 items
      pageType = PocTaboolaPageType.article_vod
    }
  }
  return pageType
}
