import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { usePage } from './usePage'
import { subtitlesData } from '../../components/Player/Helpers/constants'
import Cookies from 'universal-cookie'

/**
 * Returns the window object but only when it can be used for rendering
 * When it can't be used null will be returned (even if we're still on client-side)
 * Usage:
 * @example
 * const win = useWindow()
 * return <div>{win ? win.innerWidth : "not inside a window"}</div>
 */
export function useWindow() {
  const [canRender, setCanRender] = useState(false)
  useEffect(() => setCanRender(true), [])
  return canRender ? window : null
}

export const useSubtitles = () => {
  const [subTitlesOn, setSubTitlesOn] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    setSubTitlesOn(cookies.get(subtitlesData.video_accessibility_cookie_name))
  }, [])

  return subTitlesOn
}
export const usePushLink = () => {
  const { asPath } = useRouter()
  const page = usePage()
  const [link, setLink] = useState<null | string>(null)
  useEffect(() => {
    if (asPath === '/') {
      setLink(
        'reshet-first://present?screen_id=a4914162-17d8-472e-bf90-f28275f2f51d'
      )
    } else {
      setLink(
        document.location.origin.replace(/http(s*)/, 'reshettv') +
          document.location.pathname +
          '?utm_source=push'
      )
    }
  }, [asPath, page?.SEO.canonical])
  return link
}

export const useRouteChange = () => {
  const router = useRouter()
  const [oldPath, setOldPath] = useState<string[]>([router.asPath])

  useEffect(() => {
    const onRouteChangeStart = (url: string) => {
      setOldPath(prev => [...prev, url])
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => router.events.off('routeChangeStart', onRouteChangeStart)
  }, [router.asPath, router.events])

  // useEffect(() => {
  //   if (oldPath.length === 0) {
  //     setOldPath([router.asPath])
  //   } else {
  //     if (oldPath[oldPath.length - 1] !== router.asPath) {
  //       setOldPath([...oldPath, router.asPath])
  //     }
  //   }
  // }, [oldPath, router.asPath])
  return oldPath
}
